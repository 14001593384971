export const ChatHead = ({message, setOrganizationId}) => {
    return(
        <>
            <div className="m-0 p-0" style={{overflow: "hidden", height: "18%", cursor: "pointer"}} onClick={() => {setOrganizationId(message?.organizationId._id)}}>
                <div className="d-flex justify-content-between p-2">
                    <div className="mx-1 d-flex">
                        <img src={message?.organizationId?.coverImage} style={{height: "65px", width: "65px", borderRadius: "50%"}}
                            className="img-fluid shadow" alt="" />
                        <div className="mt-2 mx-3 text-center">
                            <span style={{fontSize: "20px", fontWeight: "600", color: "#235183d6"}}>{message?.organizationId?.name}</span><br/>
                            <span style= {{fontSize: "12px", textTransform: "uppercase", color: "#235183d6"}}>{message?.message[0]?.message}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center">
                <hr className="mt-1 mb-1 w-75" />
            </div>
        </>
    )
}