import { CompanyCard } from "./CompanyCard"

export const CompanyList = ({organizations}) => {
    return(
        <div className="container my-5">
            <div>
                <h1 className="text-center" style={{color: "#235183"}}>ORGANIZATIONS</h1>
            </div>
            <div className="mt-4">
                <div className="row">
                    {
                        organizations && organizations?.map((item, index) => {
                            return (
                                <CompanyCard key={index} details={item}/>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}