import React from 'react'
import { OnGoingEventList } from '../../components/user/candidateHome/OnGoingEventList';
import { Footer } from '../../components/user/Footer'
import { NavBar } from '../../components/user/NavBar';

export const AllOngoingEventsPage = () => {
  return (
    <>
      <NavBar />
      <div style={{minHeight: '72vh'}}>
        <OnGoingEventList/>
      </div>
      <Footer />
    </>
  )
}
