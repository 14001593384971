import React, { useState } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { axiosAuthInstance } from "../../../axios";
import { UserContext } from "../../../context/AuthProvider";
import { Popup } from "../../Popup";
import Moment from "react-moment";

export const OnGoingEventCard = ({
  buttonText,
  colour,
  event,
  getEvents,
  onGoing,
}) => {
  let navigate = useNavigate();
  const { user } = useContext(UserContext);

  const [pop, setPop] = useState(false);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");

  const handleRegisteration = () => {
    axiosAuthInstance({
      method: "post",
      url: "api/user/requestEventJoin",
      data: {
        userId: user.userID,
        eventId: event?._id,
      },
    })
      .then((res) => {
        if (res.data.statusCode == 403) {
          localStorage.clear();
          navigate("/");
        }
        setBody(res.data.message);
        setTitle(res.data.status);
        setPop(true);
        getEvents();
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Popup show={pop} setShow={setPop} title={title} body={body} />
      <div className="col-lg-3 col-md-6 mt-5">
        <div
          className="border shadow"
          style={{ borderRadius: "1rem", position: "relative" }}
          onClick={() => {}}
        >
          <div
            style={{
              background: "#235183",
              height: "60px",
              width: "85px",
              position: "absolute",
              color: "white",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "1rem",
              top: "-30px",
              left: "10px",
              fontSize: "12px"
            }}
          >
            <div className="text-center p-1" style={{ width: "80%" }}>
              <Moment date={onGoing ? event.eventEndDate : event.eventStartDate} format="ddd DD" />
            </div>
            <div
              className="text-center p-1"
              style={{ borderTop: "2px solid white", width: "80%" }}
            >
              <Moment date={onGoing ? event.eventEndDate : event.eventStartDate} format="MMM" />
            </div>
          </div>
          <div
            style={{
              background: "white",
              height: "30px",
              width: "100px",
              position: "absolute",
              color: "white",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "1rem 1rem 0 0",
              top: "-30px",
              left: "105px",
              color: "#235183",
              fontWeight: "bold",
              fontSize: "12px"
            }}
            >{onGoing ? "Ends at " : "Start At "}</div>
          <div
            style={{
              background: "#235183",
              height: "30px",
              width: "100px",
              position: "absolute",
              color: "white",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "0 0 1rem 1rem",
              top: "0px",
              left: "105px",
              fontSize: "12px"
            }}><Moment date={onGoing ? event.eventEndDate : event.eventStartDate} format="hh:mm A" /></div>
          <img
            className="img-fluid"
            style={{ height: "200px", width: "100%" }}
            src={event?.image}
            alt=""
          />
          {/* <div style={{  }} className="mt-2 text-center">
            {onGoing ? "Ends at: " : "Start At: "}
            <Moment date={onGoing ? event.eventEndDate : event.eventStartDate} format="ddd DD MMM yy hh:mm A" />
          </div> */}
          <div style={{}} className="mx-2 mb-3">
            <div
              style={{ fontWeight: "bold", fontSize: "24px", color: "#235183" }}
              className="mt-3 text-center"
            >
              {event?.name}
            </div>
            <div className="mt-1 text-center customScroll" style={{height: "75px", overflow: "auto"}}>{event?.description}</div>
          </div>
          <div style={{ display: "flex", justifyContent: "stretch" }}>
            <div
              style={{
                borderRight: "1px solid black",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "60px",
                borderRadius: "0 0 0 1rem",
                backgroundColor: "#235183",
                color: "white",
                width: "30%",
              }}
            >
              <span>{event?.registeredUser?.length}</span>
              <span>Users</span>
            </div>
            <div
              style={{
                borderRight: "1px solid black",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "60px",
                borderRadius: "0 0 0 0",
                backgroundColor: "#235183",
                color: "white",
                width: "30%",
              }}
            >
              <span>{event?.registeredOrganization?.length}</span>
              <span>Org</span>
            </div>
            <button
              onClick={() => {
                if (buttonText === "View") {
                  navigate("/Event", {
                    state: {
                      eventId: event?._id,
                    },
                  });
                } else if (buttonText === "Register") {
                  handleRegisteration();
                }
              }}
              style={{
                border: "2px solid #235183",
                height: "60px",
                borderRadius: "0 0 1rem 0",
                backgroundColor: "white",
                color: `${colour}`,
                fontWeight: "600",
                width: "40%",
              }}
            >
              {buttonText}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
