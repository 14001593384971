import { MdDelete, MdOutlineDownloadDone } from 'react-icons/md'
import { AiFillEye } from 'react-icons/ai'
import { useEffect, useState } from 'react'
import profile from '../../../assets/images/contact.jpg'
import { ButtonMailto } from '../../user/ButtonMailTo'
import { useNavigate } from 'react-router-dom'
import { axiosAdminAuthInstance } from '../../../axios'
import { ViewPopup } from '../ViewPopup'

export const OrganizationCard = ({ name, email, number, parentContainer, isActive, profileImage, description, organizationId, getRequests, getAllOrgs }) => {
    const [modalOpen, setModalOpen] = useState(false)
    const [check, setCheck] = useState(isActive == true ? true : false)
    let navigate = useNavigate();

    return (
        <>
            <div className="row gx-0 my-2 d-flex justify-content-between" style={{ borderRadius: "1rem", backgroundColor: "#F6F6F6", color: "#235183" }}>
                <div className="col-2" style={{ overflow: "hidden" }}>
                    <h6 className='mt-2' style={{ padding: "8px", marginLeft: "15px" }}>{name}</h6>
                </div>
                <div className="col-2">
                    <h6 className='mt-2' style={{ padding: "8px" }}>{email}</h6>
                </div>
                <div className="col-2">
                    <h6 className='mt-2' style={{ padding: "8px" }}>{number}</h6>
                </div>
                <div className="col-1">
                    <h6 style={{ padding: "8px", display: "flex", justifyContent: "center", cursor: "pointer" }} onClick={() => { }}>
                        {parentContainer === "list" ? <div className="form-check form-switch mx-2">
                            <input
                                style={{ height: '1.5rem', width: '3em' }}
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckDefault"
                                checked={check}
                                onChange={() => {
                                    axiosAdminAuthInstance({
                                        method: "post",
                                        url: "api/admin/blockUnblockedOrganization",
                                        data: {
                                            organizationId,
                                            status: check ? 1 : 2
                                        }
                                    }).then((res)=>{
                                        if(res.data.statusCode == 403){
                                            localStorage.clear();
                                            navigate("/adminlogin")
                                          }
                                    })
                                        .catch(err => console.log(err))
                                    setCheck(prevStat => !prevStat)
                                }
                                }
                            />
                        </div> : <MdOutlineDownloadDone size={30} onClick={() => {
                            axiosAdminAuthInstance({
                                method: "post",
                                url: "api/admin/organizationRequestAction",
                                data: {
                                    organizationId,
                                    action: 1
                                }
                            }).then(res => {
                                if(res.data.statusCode == 403){
                                    localStorage.clear();
                                    navigate("/adminlogin")
                                  }
                                getRequests()
                            }).catch(err => console.log(err))
                        }} color="green" />}
                    </h6>
                </div>
                {/* if list, Card is used in component Organization else in OrgRequests*/}
                <div className="col-1">
                    {parentContainer === "list" ? <h6
                        style={{ padding: "8px", display: "flex", justifyContent: "center", cursor: "pointer" }}
                        onClick={() => { }}>
                        <MdDelete size={30} color="red" onClick={() => {
                            axiosAdminAuthInstance({
                                method: "post",
                                url: "api/admin/deleteOrganization",
                                data: {
                                    organizationId
                                }
                            }).then((res) => {
                                if(res.data.statusCode == 403){
                                    localStorage.clear();
                                    navigate("/adminlogin")
                                  }
                                getAllOrgs()
                            })
                        }} />
                    </h6> : <h6
                        style={{ padding: "8px", display: "flex", justifyContent: "center", cursor: "pointer" }}
                        onClick={() => {
                            axiosAdminAuthInstance({
                                method: "post",
                                url: "api/admin/organizationRequestAction",
                                data: {
                                    organizationId,
                                    action: 2
                                }
                            }).then(res => {
                                if(res.data.statusCode == 403){
                                    localStorage.clear();
                                    navigate("/adminlogin")
                                  }
                                getRequests()
                            }).catch(err => console.log(err))
                        }}>
                        <MdDelete size={30} color="red" />
                    </h6>
                    }
                </div>
                <div className="col-1">
                    <h6 style={{ padding: "8px", display: "flex", justifyContent: "center", cursor: "pointer" }} onClick={() => setModalOpen(true)}> <AiFillEye size={30} /></h6>
                </div>
            </div>
            <ViewPopup isOpen={modalOpen} setIsOpen={setModalOpen} profileImage={profileImage} description={description} name={name} email={email} number={number} />
        </>
    )
}