import React, { useState } from 'react'
import { MdDelete } from 'react-icons/md'
import { AiFillEye } from 'react-icons/ai'
import Moment from 'react-moment';
import { axiosAdminAuthInstance } from '../../../../axios';
import { Registered } from './Registered'

export const EventCard = ({ name, startingDate, startingTime, endingDate, setCurrent, eventId, setEventId, getEvents }) => {
    const [modalOpen, setModalOpen] = useState(false)
    const [openUser, setOpenUser] = useState(false);
    const [openOrg, setOpenOrg] = useState(false);
    return (
        <>
            {openUser ? <Registered isOpen={openUser} setIsOpen={setOpenUser} eventId={eventId} role="Users" /> : null}
            {openOrg ? <Registered isOpen={openOrg} setIsOpen={setOpenOrg} eventId={eventId} role="Organizations" /> : null}
            <div className="row gx-0 my-2 d-flex justify-content-between" style={{ borderRadius: "1rem", backgroundColor: "#F6F6F6", color: "#235183" }}>
                <div className="col-2" style={{ overflow: "hidden" }}>
                    <h6 className="mt-2" style={{ padding: "8px", marginLeft: "15px" }}>{name}</h6>
                </div>
                <div className="col-2">
                    <h6 className="mt-2" style={{ padding: "8px" }}>
                        <Moment date={startingDate} format="ddd DD MMM yy hh:mm A"/>
                    </h6>
                </div>
                <div className="col-2">
                    <h6 className="mt-2" style={{ padding: "8px" }}>
                        <Moment date={endingDate} format="ddd DD MMM yy hh:mm A" />
                    </h6>
                </div>
                <div className="col-1">
                    <h3  onClick={() => {
                        setOpenUser(true)
                    }} style={{ padding: "8px", display: "flex", justifyContent: "center", cursor: "pointer" }}><AiFillEye size={30} /> </h3>
                </div>
                <div className="col-1">
                    <h3 onClick={() => {
                        setOpenOrg(true)
                    }} style={{ padding: "8px", display: "flex", justifyContent: "center", cursor: "pointer" }}><AiFillEye size={30} /> </h3>
                </div>

                <div className="col-1">
                    <h6 style={{ padding: "8px", display: "flex", justifyContent: "center", cursor: "pointer" }}><MdDelete size={30} color="red" onClick={() => {
                        axiosAdminAuthInstance({
                            method: "post",
                            url: "api/admin/deleteEvent",
                            data: {
                                eventId
                            }
                        }).then(res => {
                            getEvents()
                        }).catch(err => console.log(err))
                    }} /></h6>
                </div>
            </div>
        </>
    )
}
