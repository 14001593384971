import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import profile from "../../../assets/images/contact.jpg"
import { axiosOrgAuthInstance } from "../../../axios";


export const ChatHistory = () => {

    const organizationId = localStorage.getItem("orgID");
    const [historyChat, setHistoryChat] = useState();
    const [chats, setChats] = useState(null);
    const [userId, setUserId] = useState("");
    // const [company, setCompany] = useState();

    const navigate = useNavigate();

    const getHistoryChats = () => {
        axiosOrgAuthInstance({
            method: "post",
            url: "api/message/messengerHistoryUsersOrg",
            data: {
                organizationId: organizationId
            }
        })
            .then((res) => {
                if (res.data.statusCode == 403) {
                    localStorage.clear();
                    navigate("/organizationlogin");
                }
                if (res.data.status == "success") {
                
                    setHistoryChat(res.data.message);
                }
            })
            .catch((err) => console.log(err));
    }

    const getMessages = () => {
        axiosOrgAuthInstance({
            method: "post",
            url: "api/message/getMessage",
            data: {
                userId: userId,
                organizationId: organizationId
            }
        })
            .then((res) => {
                if (res.data.statusCode == 403) {
                    localStorage.clear();
                    navigate("/organizationlogin");
                }
                if (res.data.status == "success") {
                    setChats(res.data.message);
                }
            })
            .catch((err) => console.log(err));
    }




    useEffect(() => {
        getHistoryChats();
    }, [])

    useEffect(() => {
        if (userId !== "") {
            getMessages();
        }
    }, [userId])


    return (
        <div className="container-fluid">
            <div className="row mx-1 shadow" style={{ height: "82vh" }}>
                <div className="col-4 px-0 mx-0" style={{ border: "1px solid rgb(221, 221, 221)" }}>
                    <div className="py-4 text-center border" style={{ fontSize: "20px" }}>
                        CHAT ROOM
                    </div>

                    <div className="hidescroll" style={{ overflow: "auto", height: "68vh" }}>
                        {historyChat?.map((item) => {
                            return (
                                <>
                                    <div className="m-0 p-0" style={{ cursor: "pointer" }} onClick={() => setUserId(item?.userId?._id)}>
                                        <div className="d-flex justify-content-between p-2">
                                            <div className="mx-1 d-flex">
                                                <img src={item?.userId?.profileImage} style={{ height: "65px", width: "65px", borderRadius: "50%" }}
                                                    className="img-fluid shadow" alt="" />
                                                <div className="mt-2 mx-3 text-center">
                                                    <span style={{ fontSize: "16px", color: "#235183d6" }}>{item?.userId?.name}</span><br />
                                                    <span style={{ fontSize: "12px", textTransform: "uppercase", color: "#235183d6" }}>{item?.message[0]?.message}</span>
                                                </div>
                                            </div>
                                            <div>
                                                {/* <i className="fa-solid fa-circle-dot" style="color: #235183;"></i> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <hr className="mt-1 mb-1 w-75" />
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </div>
                <div className="col-8 m-0 p-0" style={{ border: "1px solid rgb(221, 221, 221)", position: "relative" }}>

                    {userId !== "" ?
                        <div className="m-0 p-0" style={{ backgroundColor: "#235183d6" }}>
                            <div className="d-flex justify-content-between p-2">
                                <div className="mx-1 d-flex">
                                    <img src={chats?.userId?.profileImage} style={{ height: "65px", width: "65px", borderRadius: "50%" }}
                                        className="img-fluid shadow" alt="" />
                                    <div className="mt-2 mx-3 text-center">
                                        <span style={{ fontSize: "16px", color: "white" }}>{chats?.userId?.name}</span><br />
                                        <span style={{ fontSize: "12px", textTransform: "uppercase", color: "white" }}>Web
                                            Developer</span>
                                    </div>
                                </div>
                                <div className="mt-2">
                                    <button className="p-3"
                                        style={{ backgroundColor: "white", border: "none", borderRadius: "1.5rem", fontSize: "12px" }}>View
                                        Profile</button>
                                </div>
                            </div>
                        </div>
                        :
                        <></>
                    }


                    <div className="mt-4 mx-3 hidescroll"style={{height:"56vh",overflow:"auto"}}>
                        {chats?.message?.map((item) => {
                            return (
                                item.sender == "user" ?
                                    <div className="d-flex justify-content-start">
                                        <div className="text-white my-1" style={{ backgroundColor: "#466D97", width: "max-content", padding: ".8rem 1rem .8rem 1rem", borderRadius: "2rem", fontSize: "14px" }}>
                                            {item?.message}
                                        </div>
                                    </div>
                                    :
                                    <div className="d-flex justify-content-end">
                                        <div className="my-1" style={{ color: "#466D97", width: "max-content", padding: ".8rem 1rem .8rem 1rem", borderRadius: "2rem", fontSize: "14px", border: "1px solid rgb(221, 221, 221)" }}>
                                            {item?.message}
                                        </div>
                                    </div>
                            )
                        })}
                    </div>

                </div>
            </div>
        </div>
    )
}