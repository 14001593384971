import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Login } from './pages/user/Login';
import { AdminDashboard } from './pages/admin/AdminDashboard';
import { CandidadteHome } from './pages/user/CandidateHome';
import { AuthProvider } from './context/AuthProvider';
import { Signup } from './pages/user/Signup';
import { UserProfile } from './pages/user/Userprofile'
import { CompanyProfile } from './pages/user/CompanyProfile';
import { UserChatHistory } from './pages/user/UserChatHistory';
import { UserMessages } from './pages/user/UserMessages';
import { AdminLogin } from './pages/admin/Login';
import { AdminSignup } from './pages/admin/Signup';
import { OrganizationLogin } from './pages/organization/Login';
import { OrganizationSignup } from './pages/organization/Signup';
import { OrganizationDashboard } from "./pages/organization/OrganizationDashboard";
import "./assets/css/style.css"
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css';
import { ViewEvent } from './pages/user/ViewEvent';
import { CompanyProfile2 } from './components/user/ViewEvent/CompanyProfile2';
import { AdminProtected } from './components/protected/AdminProtected';
import { OrgProtected } from './components/protected/OrgProtected';
import { UserProtected } from './components/protected/UserProtected';
import { AllOngoingEventsPage } from './pages/user/AllOngoingEventsPage';
import { AllUpcomingEventsPage } from './pages/user/AllUpcomingEventsPage';
import { AllCompaniesPage } from './pages/user/AllCompaniesPage';
import { ViewEventOrg } from './pages/organization/ViewEventOrg';
import { UserEventProfile } from './pages/organization/UserEventProfile';
import { OrganizationProfile } from './pages/organization/OrganizationProfile';
import { OrganizationUserLogin } from './pages/organizationUser/Login';
import { OrganizationUserDashboard } from './pages/organizationUser/OrganizationUserDashboard';
import { CreateOrganizationUser } from './components/organization/organizationusers/OrganizationUserCreate';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/candidatehome" element={
            <UserProtected>
              <CandidadteHome />
            </UserProtected>
          } />
          <Route path="/userprofile" element={
            <UserProtected>
              <UserProfile />
            </UserProtected>
          } />
          <Route path="/companyprofile" element={
            <UserProtected>
              <CompanyProfile />
            </UserProtected>
          } />
          <Route path="/companyeventprofile" element={
            <UserProtected>
              <CompanyProfile2 />
            </UserProtected>
          } />
          <Route path="/userchathistory" element={
            <UserProtected>
              <UserChatHistory />
            </UserProtected>
          } />
          <Route path="/usermessages" element={
            <UserProtected>
              <UserMessages />
            </UserProtected>
          } />
          <Route path="/Event" element={
            <UserProtected>
              <ViewEvent />
            </UserProtected>
          } />
          <Route path="/allOngoingEvents" element={
            <UserProtected>
              <AllOngoingEventsPage />
            </UserProtected>
          } />
          <Route path="/allUpcomingEvents" element={
            <UserProtected>
              <AllUpcomingEventsPage />
            </UserProtected>
          } />
          <Route path="/allCompanies" element={
            <UserProtected>
              <AllCompaniesPage />
            </UserProtected>
          } />

          <Route path='/organizationlogin' element={<OrganizationLogin />} />
          <Route path='/organizationsignup' element={<OrganizationSignup />} />
          <Route path='/organizationdashboard' element={
            <OrgProtected>
              <OrganizationDashboard />
            </OrgProtected>
          }
          />
          <Route path='/eventOrg' element={
            <OrgProtected>
              <ViewEventOrg />
            </OrgProtected>
          }
          />
          <Route path='/user-org-Profile' element={
            <OrgProtected>
              <UserEventProfile />
            </OrgProtected>
          }
          />

          <Route path='/organizationUserlogin' element={<OrganizationUserLogin />} />
          <Route path='/organizationUserDashboard' element={<OrganizationUserDashboard />} />
          <Route path='/CreateOrganizationUser' element={<CreateOrganizationUser />} />

          <Route path='/adminlogin' element={<AdminLogin />} />
          {/* <Route path='/adminsignup' element = { <AdminSignup /> } /> */}
          <Route path="/admindashboard" element={
            <AdminProtected>
              <AdminDashboard />
            </AdminProtected>
          }
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
