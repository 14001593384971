import {NavBar} from "../../components/user/NavBar"
import {Footer} from "../../components/user/Footer"
import { CompanyDetails } from "../../components/user/companyProfile/CompanyDetails"

export const CompanyProfile = () => {
    return(
        <>
            <NavBar />
            <CompanyDetails inEvent = {false}/>
            <div style={{height: "17vh"}}>

            </div>
            <Footer />
        </>
    )
}