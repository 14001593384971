import React, { useState } from 'react'
import { CompanyList } from '../../components/user/candidateHome/CompanyList';
import { Footer } from '../../components/user/Footer'
import { Banner } from "../../components/user/candidateHome/Banner"
import { NavBar } from '../../components/user/NavBar';
import { NavBar2 } from '../../components/user/NavBar2';

export const AllCompaniesPage = () => {
    const [search, setSearch] = useState("");
    const [companyList,setCompanyList] = useState([])
  return (
    <section className="home">
        <NavBar2 />
        <Banner companyList={companyList} search={search} setSearch={setSearch}/>
        <CompanyList companyList={companyList} setCompanyList={setCompanyList} search={search.toLocaleLowerCase()}/>
        <Footer />
    </section>
    // <>
    //   <NavBar />
    //   <div style={{minHeight: '72vh'}}>
        
    //   </div>
    //   <Footer />
    // </>
  )
}
