import React from 'react';
import {GrLinkedin, GrFacebookOption, GrTwitter, GrGithub, GrInstagram, GrGoogle} from 'react-icons/gr'

export const Footer = () => {
    return(
        <footer className="text-center text-lg-start text-white" 
            style={{backgroundColor: "#F8F9FA", color: `#235183 !important`}}>
            {/* <!-- Section: Social media --> */}
            <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
                {/* <!-- Left --> */}
                <div className="me-5 d-none d-lg-block">
                    <span style={{color: "#235183"}}>Get connected with us on social networks:</span>
                </div>
                {/* <!-- Left --> */}

                {/* <!-- Right --> */}
                <div>
                    <a href="#" className="me-4 text-reset">
                        <GrFacebookOption size = "16px" color= "#235183"/>
                    </a>
                    <a href="#" className="me-4 text-reset">
                        <GrTwitter size = "16px" color= "#235183" />
                    </a>
                    <a href="#" className="me-4 text-reset">
                        <GrGoogle size = "16px" color= "#235183"/>
                    </a>
                    <a href="#" className="me-4 text-reset">
                        <GrInstagram size = "16px" color= "#235183"/>
                    </a>
                    <a href="#" className="me-4 text-reset">
                        <GrLinkedin size = "16px" color= "#235183"/>
                    </a>
                    <a href="#" className="me-4 text-reset">
                        <GrGithub size = "16px" color= "#235183"/>
                    </a>
                </div>
                {/* <!-- Right --> */}
            </section>
            {/* <!-- Section: Social media --> */}

            {/* <!-- Copyright --> */}
            <div className="text-center p-3"
                style={{backgroundColor: "#235183", color: "white"}}>
                © 2022 Copyright:
                <a className="text-reset fw-bold" href="#" style={{textDecoration: "none", fontSize: "18px"}}>TipTop Career Fair</a>
            </div>
        </footer>
    )
}