import { Link, useNavigate } from "react-router-dom"
import { FiLogOut } from "react-icons/fi"
import { AiOutlineSearch, AiOutlineDown, AiOutlineRight } from "react-icons/ai"
import { MdFormatListBulleted, MdOutlineFileUpload } from "react-icons/md"
import { BsCalendar3Event } from 'react-icons/bs'
import { useState } from "react"
import { Users } from "../../components/admin/userslist/Users"
import { Organizations } from "../../components/admin/organizationlist/Organizations"
import { Events } from "../../components/admin/event/list/Events"
import { CreateEvent } from "../../components/admin/event/create/CreateEvent"
import { Requests } from "../../components/admin/event/requests/Requests"
import { UserRequests } from "../../components/admin/userslist/UserRequests"
import { OrgRequests } from "../../components/admin/organizationlist/OrgRequests"
import "../../assets/css/dashboard.css"

export const AdminDashboard = () => {
    const [eventDropDown, seteventDropDown] = useState(false);
    const [userDropDown, setUserDropDown] = useState(true);
    const [orgDropDown, setOrgDropDown] = useState(false);
    const [showComponent, setShowComponent] = useState("userList");
    const [search, setSearch] = useState("");
    const [pop, setPop] = useState(false);

    let Navigate = useNavigate()
    return (
        <>
            <div className="parentContainer">
                <div>
                    <div className="shadow">
                        <h2 style={{ textAlign: "center", marginTop: "10%", fontSize: "28px", textTransform: "uppercase", color: "white", fontWeight: "700" }}>Virtual Career Fair</h2>
                        <ul style={{ padding: 0, margin: "20% 0", textAlign: "left" }}>
                            <li onClick={() => {
                                setUserDropDown(!userDropDown)
                                seteventDropDown(false)
                                setOrgDropDown(false)
                            }}>
                                <Link>
                                    <BsCalendar3Event size={30} />
                                    <div className="d-flex justify-content-between w-100" style={{ paddingLeft: "30px", fontSize: "16px" }}>
                                        <span style={{ paddingRight: "5px" }}>USERS</span>
                                        {userDropDown ? <AiOutlineDown size={18} /> : <AiOutlineRight size={18} />}
                                    </div>
                                </Link>
                            </li>
                            <li
                                className={(userDropDown && showComponent === "userList") ? "activate" : (userDropDown && showComponent !== "userList") ? "" : "deactivate"}
                                onClick={() => setShowComponent("userList")}
                                style={{ padding: "10px 20px" }}>
                                <Link style={{ display: "flex", alignItems: "flex-end", paddingLeft: "30px" }}>
                                    <MdOutlineFileUpload size={30} />
                                    <span >LIST</span>
                                </Link>
                            </li>
                            <li
                                className={(userDropDown && showComponent === "userRequest") ? "activate" : (userDropDown && showComponent !== "userRequest") ? "" : "deactivate"}
                                onClick={() => setShowComponent("userRequest")}
                                style={{ padding: "10px 20px" }}>
                                <Link style={{ display: "flex", alignItems: "flex-end", paddingLeft: "30px" }}>
                                    <MdFormatListBulleted size={30} />
                                    <span>REQUESTS</span>
                                </Link>
                            </li>
                            <li onClick={() => {
                                setUserDropDown(false)
                                seteventDropDown(false)
                                setOrgDropDown(!orgDropDown)
                            }}>
                                <Link>
                                    <BsCalendar3Event size={30} />
                                    <div className="d-flex justify-content-between w-100" style={{ paddingLeft: "30px", fontSize: "16px" }}>
                                        <span style={{ paddingRight: "5px" }}>ORGANIZATIONS</span>
                                        {orgDropDown ? <AiOutlineDown size={18} /> : <AiOutlineRight size={18} />}
                                    </div>
                                </Link>
                            </li>
                            <li
                                className={(orgDropDown && showComponent === "orgList") ? "activate" : (orgDropDown && showComponent !== "orgList") ? "" : "deactivate"}
                                onClick={() => setShowComponent("orgList")}
                                style={{ padding: "10px 20px" }}>
                                <Link style={{ display: "flex", alignItems: "flex-end", paddingLeft: "30px" }}>
                                    <MdOutlineFileUpload size={30} />
                                    <span >LIST</span>
                                </Link>
                            </li>
                            <li
                                className={(orgDropDown && showComponent === "orgRequest") ? "activate" : (orgDropDown && showComponent !== "orgRequest") ? "" : "deactivate"}
                                onClick={() => setShowComponent("orgRequest")}
                                style={{ padding: "10px 20px" }}>
                                <Link style={{ display: "flex", alignItems: "flex-end", paddingLeft: "30px" }}>
                                    <MdFormatListBulleted size={30} />
                                    <span>REQUESTS</span>
                                </Link>
                            </li>
                            <li onClick={() => {
                                setUserDropDown(false)
                                seteventDropDown(!eventDropDown)
                                setOrgDropDown(false)
                            }}>
                                <Link>
                                    <BsCalendar3Event size={30} />
                                    <div className="d-flex justify-content-between w-100" style={{ paddingLeft: "30px", fontSize: "16px" }}>
                                        <span style={{ paddingRight: "5px" }}>EVENT</span>
                                        {eventDropDown ? <AiOutlineDown size={18} /> : <AiOutlineRight size={18} />}
                                    </div>
                                </Link>
                            </li>
                            <li
                                className={(eventDropDown && showComponent === "create") ? "activate" : (eventDropDown && showComponent !== "create") ? "" : "deactivate"}
                                onClick={() => setShowComponent("create")}
                                style={{ padding: "10px 20px" }}>
                                <Link style={{ display: "flex", alignItems: "flex-end", paddingLeft: "30px" }}>
                                    <MdOutlineFileUpload size={30} />
                                    <span >CREATE</span>
                                </Link>
                            </li>
                            <li
                                className={(eventDropDown && showComponent === "listEvents") ? "activate" : (eventDropDown && showComponent !== "listEvents") ? "" : "deactivate"}
                                onClick={() => setShowComponent("listEvents")}
                                style={{ padding: "10px 20px" }}>
                                <Link style={{ display: "flex", alignItems: "flex-end", paddingLeft: "30px" }}>
                                    <MdFormatListBulleted size={30} />
                                    <span>LIST EVENTS</span>
                                </Link>
                            </li>
                            <li
                                className={(eventDropDown && showComponent === "requests") ? "activate" : (eventDropDown && showComponent !== "requests") ? "" : "deactivate"}
                                onClick={() => setShowComponent("requests")}
                                style={{ padding: "10px 20px" }}>
                                <Link style={{ display: "flex", alignItems: "flex-end", paddingLeft: "30px" }}>
                                    <MdFormatListBulleted size={30} />
                                    <span>REQUESTS</span>
                                </Link>
                            </li>
                            <li onClick={() => {
                                localStorage.clear()
                                Navigate("/adminlogin")
                            }}>
                                <Link>
                                    <FiLogOut size={30} />
                                    <span>LOGOUT</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", height: "98vh", width: "80%", borderRadius: "25px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", height: "13vh", alignItems: "center" }}>
                            <h2 style={{ paddingLeft: "30px" }}><span style={{ color: "#235183", fontWeight: "600" }}>Admin Portal</span> </h2>
                            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", paddingRight: "50px" }}>
                                <div className="border shadow" style={{ borderRadius: "10px", padding: "0 8px" }}>
                                    <button style={{ height: "40px", padding: "0 10px", border: "none", backgroundColor: "transparent" }}>
                                        <AiOutlineSearch />
                                    </button>
                                    <input
                                        style={{ height: "40px", padding: "0 10px", border: "none", outline: "none" }}
                                        placeholder="Search"
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                </div>
                                <div style={{ paddingLeft: "30px" }}>
                                    <h5 style={{ padding: 0, margin: 0 }}>Admin</h5>
                                </div>
                                <button onClick={() => {
                                    localStorage.clear()
                                    Navigate("/adminlogin")
                                }} className="border shadow" style={{ marginLeft: "30px", padding: "5px 10px", backgroundColor: "white", borderRadius: "10px" }}><FiLogOut /></button>
                            </div>
                        </div>

                        <div style={{ height: "84vh" }}>
                            {showComponent === "userList" ? <Users search={search.toLowerCase()} /> : null}
                            {showComponent === "userRequest" ? <UserRequests search={search.toLowerCase()} /> : null}
                            {showComponent === "orgList" ? <Organizations search={search.toLowerCase()} /> : null}
                            {showComponent === "orgRequest" ? <OrgRequests search={search.toLowerCase()} /> : null}
                            {showComponent === "create" ? <CreateEvent /> : null}
                            {showComponent === "listEvents" ? <Events search={search.toLowerCase()} /> : null}
                            {showComponent === "requests" ? <Requests search={search.toLowerCase()} /> : null}
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
