import React, { useEffect, useState } from "react";
import { FiLogOut } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { axiosAuthInstance } from "../../axios";

export const OrgNavbar2 = () => {
    const navigate = useNavigate();

    const [userData, setUserData] = useState({
        name: "",
        profileImage: ""
    })

    // const getUserProfile = () => {
    //     axiosAuthInstance({
    //         method: "post",
    //         url: "api/user/userDetails",
    //     }).then(res => {
    //         if (res.data.statusCode == 403) {
    //             localStorage.clear();
    //             navigate("/");
    //         }
    //         if (res.data.status === "success") {
    //             setUserData(prevStat => {
    //                 return ({
    //                     ...prevStat,
    //                     name: res.data.message.name,
    //                     profileImage: res.data.message.profileImage,
    //                 })
    //             })
    //         }
    //     }).catch(err => console.log(err))
    // }

    useEffect(() => {
        // getUserProfile()
    }, [])

    const logOut = () => {
      
        localStorage.clear();
        // navigate("/");
        window.location.reload();
    }

    return(
        <nav className="navbar navbar-expand-lg navbar-dark" style={{backgroundColor: "#235183"}}>
            <div className="container-fluid">
                <Link className="navbar-brand mx-5" to="/organizationdashboard">Brand</Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mb-2 mb-lg-0 d-flex justify-content-end w-100 mx-5">
                        {/* <li className="nav-item">
                            <Link to= "/candidatehome" className="nav-link" aria-current="page">Dasboard</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/userchathistory" className="nav-link">Log Out</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/usermessages">Messages</Link>
                        </li> */}
                        <li className="nav-item">
                            <Link className="nav-link" to="/organizationdashboard">Dashboard</Link>
                        </li>
                        <li className="nav-item">
                        <Link className="nav-link" to="/organizationlogin" style={{display: 'flex', alignItems: 'center'}}>
                                <span>Log Out</span> 
                                <FiLogOut size={22} style={{marginLeft: '5px'}}/>
                            </Link>
                        </li>
                        {/* <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" to="" id="navbarDropdown" role="button"
                                data-bs-toggle="dropdown" aria-expanded= "false">
                                {userData?.name}
                            </Link>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li>
                                    <Link className="dropdown-item" to="/userprofile">
                                        <div className="d-flex">
                                            <img src= {userData?.profileImage}
                                                style={{height: "50px", width: "50px", borderRadius: "50%"}} alt="" />
                                            <div className="mx-2 mt-3">
                                                <span style={{fontSize: "16px"}}>Profile</span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link className="dropdown-item" to="#">
                                        <div className="mt-2 mx-2">
                                            <span className="mx-2" style={{fontSize: "16px"}} onClick={() => {logOut()}}>Log Out</span>
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                        </li> */}
                    </ul>
                </div>
            </div>
        </nav>
    )
}