import { Link, useNavigate } from "react-router-dom"
import { axiosOrgAuthInstance } from "../../../axios"
import { useEffect, useState } from "react"
import { filterBy } from "../../../functions"
import { Loader } from "../../Loader"
import { OrganizationUserCard } from "./OrganizationUserCard"


export const OrganizationUsers = ({ search }) => {
    const itemsPerPage = 10
    let navigate = useNavigate();
    const [isloading, setloading] = useState(true)
    const [allOrgUsers, setAllOrgUsers] = useState([])
    const [organizationUserList, setOrganizationUserList] = useState([])
    const [currentPage, setCurrentPage] = useState(0);
    const [totalItems, setTotalItems] = useState(0);

    const getAllOrgUsers = () => {
        axiosOrgAuthInstance({
            method: "post",
            url: "api/organization-user/list",
            data: {
                currentPage
            }
        })
            .then(res => {
                if (res.data.statusCode == 403) {
                    localStorage.clear();
                    navigate("/organizationLogin")
                }
                setAllOrgUsers(res.data.message)
                setOrganizationUserList(res.data.message)
                setTotalItems(res.data.itemsCount)
                setloading(false)
            }).catch(err => console.log(err))
    }
    useEffect(() => {
        getAllOrgUsers()
    }, [currentPage])

    useEffect(() => {
        setOrganizationUserList(filterBy(allOrgUsers, search))
    }, [search])

    const paginationArray = []
    let startIndex = 0
    if (Math.ceil(totalItems / itemsPerPage) == 2 || Math.ceil(totalItems / itemsPerPage) == 1) { }
    else if (Math.ceil(totalItems / itemsPerPage) == 3) {
        paginationArray.push(1);
    }
    else if (currentPage == Math.ceil(totalItems / itemsPerPage) - 1) {
        paginationArray.push(Math.ceil(totalItems / itemsPerPage) - 3);
        paginationArray.push(Math.ceil(totalItems / itemsPerPage) - 2);
    } else {
        for (let i = currentPage - 1; i < Math.ceil(totalItems / itemsPerPage); i++) {
            if (startIndex >= 2) break;
            if (i < 1) continue;
            paginationArray.push(i);
            startIndex++
        }
    }

    return (
        <>
            <div style={{ height: "77vh" }}>
                <div className="row gx-0 d-flex justify-content-between" style={{ backgroundColor: "#235183", borderRadius: ".5rem", color: "white" }}>
                    <div className="col-2">
                        <h3 style={{ padding: "8px", paddingBottom: 0, fontSize: "18px", marginLeft: "15px" }}>Name</h3>
                    </div>
                    <div className="col-2">
                        <h3 style={{ padding: "8px", paddingBottom: 0, fontSize: "18px" }}>Email</h3>
                    </div>
                    <div className="col-2">
                        <h3 style={{ padding: "8px", paddingBottom: 0, fontSize: "18px" }}>Password</h3>
                    </div>

                </div>
                <div className="hidescroll" style={{ height: "72vh", overflow: "auto" }}>
                    {isloading ? <Loader /> : organizationUserList?.length > 0 ? organizationUserList?.map((element, index) => {
                        return <OrganizationUserCard key={index} serial={index + 1} getAllOrgUsers={getAllOrgUsers} name={element.name} email={element.email} password={element.password} />
                    }) : <h1>No Record Found!</h1>}
                </div>
            </div>
            <div className="mt-3">
                <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-end">
                        <li className={currentPage < 1 ? "page-item disabled" : "page-item"}>
                            <button className="page-link" to="" onClick={() => {
                                setCurrentPage(prevStat => {
                                    if (prevStat >= 1) {
                                        return prevStat - 1
                                    }
                                    return prevStat
                                })
                            }}>
                                Previous
                            </button>
                        </li>
                        <li className="page-item">
                            <button className="page-link" to="" onClick={() => setCurrentPage(0)} style={currentPage == 0 ? { background: '#235183', color: 'white' } : {}}>
                                1
                            </button>
                        </li>
                        {
                            Math.ceil(totalItems / itemsPerPage) > 4 && currentPage >= 3 ? <li className="page-item">
                                <button className="page-link" to="" onClick={() => setCurrentPage(prevStat => prevStat - 2)}>
                                    ...
                                </button>
                            </li> : null
                        }
                        {
                            paginationArray.map((item, index) => {
                                return <li key={index} className="page-item">
                                    <button className="page-link" to="" onClick={() => setCurrentPage(item)} style={currentPage == item ? { background: '#235183', color: 'white' } : {}}>
                                        {item + 1}
                                    </button>
                                </li>
                            })
                        }
                        {
                            Math.ceil(totalItems / itemsPerPage) > 4 && currentPage < Math.ceil(totalItems / itemsPerPage) - 2 ? <li className="page-item">
                                <button className="page-link" to="" onClick={() => setCurrentPage(prevStat => prevStat + 2)}>
                                    ...
                                </button>
                            </li> : null
                        }
                        {
                            Math.ceil(totalItems / itemsPerPage) == 1 ? null : <li className="page-item">
                                <button className="page-link" to="" onClick={() => setCurrentPage(Math.ceil(totalItems / itemsPerPage) - 1)} style={currentPage == Math.ceil(totalItems / itemsPerPage) - 1 ? { background: '#235183', color: 'white' } : {}}>
                                    {Math.ceil(totalItems / itemsPerPage)}
                                </button>
                            </li>
                        }
                        <li className={currentPage >= Math.ceil(totalItems / itemsPerPage) - 1 ? "page-item disabled" : "page-item"}>
                            <button className="page-link" to="" onClick={() => {
                                setCurrentPage(prevStat => {
                                    return prevStat + 1
                                })
                            }}>
                                Next
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
        </>
    )
}