import profleImage from "../../assets/images/profileDefault.png"
import banner from "../../assets/images/contact.jpg"
import "../../assets/css/style.css"
import { useNavigate } from "react-router-dom"
import { FaFileUpload } from "react-icons/fa"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { useState, useRef } from "react"
import { axiosInstance } from '../../axios/index.js'
import { linkValidation, nameValidation, phoneValidation, validateEmail } from "../../functions"
import axios from "axios";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai"
import { Popup } from "../../components/Popup"

let docTypes = ["application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/pdf"]

export const Signup = () => {

    const buttonRef = useRef(null)

    let navigate = useNavigate()
    const imagRef = useRef()
    const [pop, setPop] = useState(false);
    const [title, setTitle] = useState("");
    const [body, setBody] = useState("");
    const [CVFileName, setCVFileName] = useState("");
    const [CVFileType, setCVFileType] = useState("");

    const [cvUrl,setCVvrl] = useState('')
    const [passwordShow, setPasswordShow] = useState(false)
    const [cpasswordShow, setCpasswordShow] = useState(false)
    const inputRef = useRef(null);
    const [profileImageUp, setProfileImageUp] = useState("");
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        contact: "",
        password: "",
        confirmPassword: "",
        socials: {
            social1: {
                title: "",
                link: ""
            },
            social2: {
                title: "",
                link: ""
            },
            social3: {
                title: "",
                link: ""
            }
        },
    });
    const handleSubmit = (e) => {
        const profile = document.getElementById("profileImage");
        const CV = document.getElementById("profileImage");
      
        e.preventDefault()
        if (!validateEmail(formData.email)) {
            setBody("Invalid Email")
            setTitle("Email Failed")
            setPop(true)
        }
        else if (profile.files.length == 0){
            setBody("Profile picture not selected")
            setTitle("Profile Failed")
            setPop(true)
        }
        else if (CV.files.length == 0){
            setBody("CV not selected")
            setTitle("CV Failed")
            setPop(true)
        }
        else if (formData.password !== formData.confirmPassword) {
            setBody("password and confirm password don't match")
            setTitle("Password Failed")
            setPop(true)
        } else if (formData.password.length <= 6) {
            setBody("password length should atleast be 7")
            setTitle("Password Failed")
            setPop(true)
        } else if (!phoneValidation(formData.contact)) {
            setBody("Invalid Number, Please Provide correct Number")
            setTitle("Number Failed")
            setPop(true)
        }
        else if (!nameValidation(formData.firstName) || !nameValidation(formData.lastName)) {
            setBody("Please Provide correct Name")
            setTitle("Name field Failed")
            setPop(true)
        }
        
        else if (!docTypes.includes(CVFileType)) {
            console.log(CVFileType)
            // alert("Invalid Document Either pdf or docs")
            setBody("Invalid Document Either pdf or docs, Please provide correct format")
            setTitle("CV Failed")
            setPop(true)
        }

        else {
            const social = []
            Object.keys(formData.socials).forEach((key, index) => {
             
                const socialObject = formData.socials[key]
                if(!linkValidation(socialObject.link) && socialObject.link != ""){
                    socialObject.link = `https://${socialObject.link}`
                }
                social.push(socialObject)
            });
            axiosInstance({
                method: "post",
                url: "api/user",
                data: {
                    ...formData,
                    name: `${formData.firstName} ${formData.lastName}`,
                    socialLinks: [...social],
                    profileImage: profileImageUp,
                    CV: cvUrl
                },
            }).then(res => {
                console.log(res)
                if (res.data.status === "failed") {
                    setBody(res.data.message)
                    setTitle("Error")
                    setPop(true)
                } else if (res.data.status === "success") {
                   
                    setBody("User created successfully")
                    setTitle("Success")
                    setPop(true)
                    navigate('/')
                }
            }).catch(err => console.log(err.toJSON()))
        }
    }

    const triggerUploadProfile = () => {
        let f = document.getElementById("profileImage");
        f.click();
    }

    // Changes
    const uploadoc = async (e) => {
        const file = e.target.files[0]
        setCVFileType(file.type)
        if(file.size > 1048576 * 2){ // 1048576 = 1mb
            setBody("File size too large, max limit is 2mb")
            setTitle("file upload failed")
            setPop(true)
            return
        }else{
            setCVFileName(file.name)
            const formData = new FormData()
            formData.append(
                'image',
                file,
                file.name
            )
            await axios.post('https://backend.tiptopcareerfair.com/imageUpload', formData)
                .then(res => {
                    const cvLink = res.data.message;
                    console.log(cvLink)
                    setCVvrl(cvLink)
                })
                .catch(err => console.log(err))
        }
    }
    // Changes

    const UploadFile = async (e) => {
        const file = e.target.files[0]


        if (file) {
            const formData = new FormData()
            formData.append(
                'image',
                file,
                file.name
            )
       

            await axios.post('https://backend.tiptopcareerfair.com/imageUpload', formData)
                .then(res => {
                   
                    const imageUrl = res.data.message
                    console.log(imageUrl)
                    setProfileImageUp(imageUrl);
                })
                .catch(err => console.log(err))
        }
    }

    
    return (
        <>
            <Popup show={pop} setShow={setPop} title={title} body={body} />
            <div className="grid-container">
                <div className="item hidescroll" style={{ overflow: "auto" }}>
                    <div style={{ marginTop: "2%", textAlign: "center", height: "97vh" }}>
                        <h1 style={{ fontWeight: "600", color: "#235183" }}>USER SIGNUP</h1>
                        <form onSubmit={(e) => handleSubmit(e)}>
                            <div style={{ position: "relative", marginTop: "2%" }}>
                                <div className="plus-icon" style={{ position: "absolute", left: "54%", top: "70%", cursor: "pointer" }}>
                                    <FontAwesomeIcon icon={faPlus} size="2x" onClick={() => { triggerUploadProfile() }} />
                                    <input type="file" accept="image/png,image/jpeg,image/jpg" id="profileImage" onChange={(e) => { UploadFile(e) }} ref={imagRef} style={{ display: "none" }} />
                                </div>
                                {
                                    profileImageUp !== "" ?
                                        <>
                                            <img src={profileImageUp} style={{ width: "160px", height: "140px", borderRadius: "50%" }} alt="" />
                                        </>
                                        :
                                        <>
                                            <img src={profleImage} style={{ width: "160px", height: "140px", borderRadius: "50%" }} alt="" />
                                        </>
                                }

                            </div>
                            <div className="grid-container-2" style={{ marginLeft: "5%", marginRight: "5%" }}>

                                <div style={{ margin: "3%" }}>
                                    <label style={{ color: "#8f8f8f", fontWeight: "600" }}>FIRST NAME *</label><br />
                                    <input
                                        style={{ border: "none", textAlign: "center", borderBottom: "1px solid #8f8f8f", padding: "1%", width: "70%" }}
                                        type="text"
                                        required
                                        onChange={(e) => setFormData(prevState => {
                                            return ({
                                                ...prevState,
                                                firstName: e.target.value
                                            })
                                        })} />
                                </div>
                                <div style={{ margin: "3%" }}>
                                    <label style={{ color: "#8f8f8f", fontWeight: "600" }}>LAST NAME *</label><br />
                                    <input
                                        style={{ border: "none", textAlign: "center", borderBottom: "1px solid #8f8f8f", padding: "1%", width: "70%" }}
                                        type="text"
                                        required
                                        onChange={(e) => setFormData(prevState => {
                                            return ({
                                                ...prevState,
                                                lastName: e.target.value
                                            })
                                        })} />
                                </div>
                                <div style={{ margin: "3%" }}>
                                    <label style={{ color: "#8f8f8f", fontWeight: "600" }}>PHONE *</label><br />
                                    <input
                                        style={{ border: "none", textAlign: "center", borderBottom: "1px solid #8f8f8f", padding: "1%", width: "70%" }}
                                        type="text"
                                        required
                                        onChange={(e) => setFormData(prevState => {
                                            return ({
                                                ...prevState,
                                                contact: e.target.value
                                            })
                                        })} />
                                </div>
                                <div style={{ margin: "3%" }}>
                                    <label style={{ color: "#8f8f8f", fontWeight: "600" }}>EMAIL *</label><br />
                                    <input
                                        style={{ border: "none", textAlign: "center", borderBottom: "1px solid #8f8f8f", padding: "1%", width: "70%" }}
                                        type="text"
                                        required
                                        onChange={(e) => setFormData(prevState => {
                                            return ({
                                                ...prevState,
                                                email: e.target.value
                                            })
                                        })} />
                                </div>
                                <div style={{ margin: "3%" }}>
                                    <label style={{ color: "#8f8f8f", fontWeight: "600" }}>PASSWORD *</label><br />
                                    <div style={{ borderBottom: "1px solid #8f8f8f", width: "70%", marginLeft: "15%", display: "flex", justifyContent: "space-between" }}>
                                        <input
                                            style={{ border: "none", textAlign: "center", padding: "1%", width: "70%" }}
                                            type={passwordShow ? "text" : "password"}
                                            required
                                            onChange={(e) => setFormData(prevState => {
                                                return ({
                                                    ...prevState,
                                                    password: e.target.value
                                                })
                                            })} />

                                        <div>
                                            {passwordShow ? <AiFillEyeInvisible size={20} onClick={() => setPasswordShow(!passwordShow)} /> : <AiFillEye size={20} onClick={() => setPasswordShow(!passwordShow)} />}
                                        </div>

                                    </div>


                                </div>
                                <div style={{ margin: "3%" }}>
                                    <label style={{ color: "#8f8f8f", fontWeight: "600" }}>CONFIRM PASSWORD *</label><br />
                                    <div style={{ borderBottom: "1px solid #8f8f8f", width: "70%", marginLeft: "15%", display: "flex", justifyContent: "space-between" }}>
                                        <input
                                            style={{ border: "none", textAlign: "center", padding: "1%", width: "70%" }}
                                            type={cpasswordShow ? "text" : "password"}
                                            required
                                            onChange={(e) => setFormData(prevState => {
                                                return ({
                                                    ...prevState,
                                                    confirmPassword: e.target.value
                                                })
                                            })} />
                                        <div>
                                            {cpasswordShow ? <AiFillEyeInvisible size={20} onClick={() => setCpasswordShow(!cpasswordShow)} /> : <AiFillEye size={20} onClick={() => setCpasswordShow(!cpasswordShow)} />}
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div style={{ margin: "3%" }}>
                                <label style={{ color: "#8f8f8f", fontWeight: "600" }}>SOCIAL MEDIA LINKS</label><br />
                            </div>


                            <div className="grid-container-3" style={{ marginLeft: "8%", marginRight: "8%" }}>
                                <div>
                                    <div style={{ margin: "1%" }}>
                                        <input
                                            style={{ border: "none", textAlign: "center", borderBottom: "1px solid #8f8f8f", padding: "1%", width: "75%" }}
                                            type="text"
                                            placeholder="Site Name"
                                            onChange={(e) => setFormData(prevStat => {
                                                return ({
                                                    ...prevStat,
                                                    socials: {
                                                        ...prevStat.socials,
                                                        social1: {
                                                            ...prevStat.socials.social1,
                                                            title: e.target.value
                                                        }
                                                    }
                                                })
                                            })} />
                                    </div>
                                    <div style={{ margin: "6%" }}>
                                        <input
                                            style={{ border: "none", textAlign: "center", borderBottom: "1px solid #8f8f8f", padding: "1%", width: "75%" }}
                                            type="text"
                                            placeholder="Site Link"
                                            onChange={(e) => setFormData(prevStat => {
                                                return ({
                                                    ...prevStat,
                                                    socials: {
                                                        ...prevStat.socials,
                                                        social1: {
                                                            ...prevStat.socials.social1,
                                                            link: e.target.value
                                                        }
                                                    }
                                                })
                                            })} />
                                    </div>
                                </div>
                                <div>
                                    <div style={{ margin: "1%" }}>
                                        <input
                                            style={{ border: "none", textAlign: "center", borderBottom: "1px solid #8f8f8f", padding: "1%", width: "70%" }}
                                            type="text"
                                            placeholder="Site Name"
                                            onChange={(e) => setFormData(prevStat => {
                                                return ({
                                                    ...prevStat,
                                                    socials: {
                                                        ...prevStat.socials,
                                                        social2: {
                                                            ...prevStat.socials.social2,
                                                            title: e.target.value
                                                        }
                                                    }
                                                })
                                            })} />
                                    </div>
                                    <div style={{ margin: "6%" }}>
                                        <input
                                            style={{ border: "none", textAlign: "center", borderBottom: "1px solid #8f8f8f", padding: "1%", width: "70%" }}
                                            type="text"
                                            placeholder="Site Link"
                                            onChange={(e) => setFormData(prevStat => {
                                                return ({
                                                    ...prevStat,
                                                    socials: {
                                                        ...prevStat.socials,
                                                        social2: {
                                                            ...prevStat.socials.social2,
                                                            link: e.target.value
                                                        }
                                                    }
                                                })
                                            })} />
                                    </div>
                                </div>
                                <div>
                                    <div style={{ margin: "1%" }}>
                                        <input
                                            style={{ border: "none", textAlign: "center", borderBottom: "1px solid #8f8f8f", padding: "1%", width: "70%" }}
                                            type="text"
                                            placeholder="Site Name"
                                            onChange={(e) => setFormData(prevStat => {
                                                return ({
                                                    ...prevStat,
                                                    socials: {
                                                        ...prevStat.socials,
                                                        social3: {
                                                            ...prevStat.socials.social3,
                                                            title: e.target.value
                                                        }
                                                    }
                                                })
                                            })} />
                                    </div>
                                    <div style={{ margin: "6%" }}>
                                        <input
                                            style={{ border: "none", textAlign: "center", borderBottom: "1px solid #8f8f8f", padding: "1%", width: "70%" }}
                                            type="text"
                                            placeholder="Site Link"
                                            onChange={(e) => setFormData(prevStat => {
                                                return ({
                                                    ...prevStat,
                                                    socials: {
                                                        ...prevStat.socials,
                                                        social3: {
                                                            ...prevStat.socials.social3,
                                                            link: e.target.value
                                                        }
                                                    }
                                                })
                                            })} />
                                    </div>
                                </div>
                            </div>

                            <div className="mt-5" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                <label style={{ color: "#8f8f8f", fontWeight: "600" }}>{CVFileName ? CVFileName : "Upload Resume (PDF or DOC) max 2MB"}</label>
                                <input accept=".doc,.docx,application/msword,application/pdf" type="file" name="file" ref={inputRef} style={{ marginLeft: "15px", width: "230px", display: "none" }} onChange={(e) => uploadoc(e)} />
                                <FaFileUpload style={{ color: "#8f8f8f" }} size={30} onClick={() => inputRef.current.click()} />
                            </div>

                            <div style={{ marginTop: "4%" }}>
                                <button disabled={!cvUrl || !profileImageUp ? true : false} className="signIn-fill"
                                    style={{cursor: !cvUrl || !profileImageUp ? "not-allowed" : "pointer", width: "45%", padding: "1.5%", backgroundColor: "#235183", border: "2px solid #235183", color: "white", borderRadius: "2rem" }}>SIGN
                                    UP</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="item">
                    <div className="login-cover"
                        style={{
                            backgroundImage:
                                `linear-gradient(90deg, rgba(0, 0, 0, 0.4826681014202556) 0%, rgba(0, 0, 0, 0.7878781854538691) 100%, rgba(0, 0, 0, 1) 100%),
                        url(${banner})`, height: "100vh", backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center"
                        }}>
                        <div>
                            <h1 style={{ color: "white", paddingTop: "15%" }}>ALREADY A MEMBER?</h1>
                            <p style={{ color: "white", fontSize: "18px", marginTop: "2%" }}>Login</p>
                            <div style={{ marginTop: "80%" }}>
                                <button onClick={() => navigate("/")} className="signUp-trans"
                                    style={{ width: "70%", padding: "2%", border: "2px solid white", color: "white", backgroundColor: "transparent", borderRadius: "2rem" }}>Sign
                                    In</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>

    )
}