import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { IoArrowBack } from "react-icons/io5";
import coverImage1 from "../../../assets/images/cover1.png"
import { axiosOrgAuthInstance } from "../../../axios";
import { Popup } from "../../Popup";

export const EditProfile = ({setShowComponent}) => {
    const [show, setShow] = useState(false)
    const [title, setTitle] = useState("")
    const [body, setBody] = useState("")
    const [cover, setCover] = useState("")
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        contact: "",
        coverImage: "",
        description: "",
        socialLink: ""
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        axiosOrgAuthInstance({
            method: "post",
            url: "/api/organization/editProfile",
            data: { ...formData, coverImage: cover }
        }).then(res => {
            setFormData(res.data.user)
            setCover(res.data.user.coverImage)
            setTitle("success")
            setBody("profile updated")
            setShow(true)
        }).catch(err => console.log(err.message))
    }
    const getDetails = () => {
        axiosOrgAuthInstance({
            method: "post",
            url: "/api/organization/orgDetails"
        }).then(res => {
            if(res.data.status == "success") {
                setFormData(res.data.message)
                setCover(res.data.message.coverImage)
            }
            else {
                // setTitle("not found")
                // setBody("no organization was found")
                // setPop(true)
            }
        }).catch(err => {
            console.log(err.message)
        })
      }
    
      useEffect(() => {
        getDetails()
      }, []);

    const triggerUploadProfile = () => {
        let f = document.getElementById("coverImage");
        f.click();
    }

    const UploadFile = async (e) => {
        const file = e.target.files[0]

        if (file) {
            const formData = new FormData()
            formData.append(
                'image',
                file,
                file.name
            )
            await axios.post('https://backend.tiptopcareerfair.com/imageUpload', formData)
                .then(res => {
                    // const result = res.data.data.successResult
                    const imageUrl = res.data.message;
                    setCover(imageUrl);
                    setFormData(prevStat => {
                        return ({
                            ...prevStat,
                            coverImage: imageUrl
                        })
                    })
                })
                .catch(err => console.log(err))
        }
    }

  return (
    <div>
        <Popup show={show} setShow={setShow} title={title} body={body} />
      <div className="container">
        <div
          className="mb-2"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            // position: "absolute",
          }}
        >
          <button
            onClick={() => setShowComponent("profile")}
            className="btn"
            style={{
              backgroundColor: "#235183",
              color: "white",
              borderRadius: "50%",
              width: "60px",
              height: "60px",
            }}
          >
            <IoArrowBack size={30} />
          </button>
        </div>

        <div>
        <form onSubmit={(e) => handleSubmit(e)} style={{ position: "relative" }}>
                <div>
                    {cover !== "" ?
                        <div style={{ height: "200px", backgroundImage: `url(${cover})` }}>
                            <img src={coverImage1} style={{ position: "absolute", width: "80px", right: 0, margin: "20px 20px 0px 0px", cursor:"pointer" }} onClick={() => {triggerUploadProfile()}}/>
                            <input id="coverImage" type="file" style={{ display: "none" }} onChange={(e) => {UploadFile(e)}} />

                        </div>
                        :
                        <div style={{ height: "200px", backgroundColor: "#D5E4FF", position: "relative" }}>
                            <img src={coverImage1} style={{ position: "absolute", width: "80px", right: 0, margin: "20px 20px 0px 0px", cursor:"pointer" }} onClick={() => {triggerUploadProfile()}}/>
                            <input id="coverImage" type="file" style={{ display: "none" }} onChange={(e) => {UploadFile(e)}} />
                        </div>
                    }

                    <div className='shadow' style={{ position: "absolute", width: "60%", backgroundColor: "white", top: "60%", left: "20%" }}>
                        <div className="grid-container-2 mt-3" style={{ marginLeft: "5%", marginRight: "5%" }}>
                            <div style={{ margin: "3%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                                <div style={{ color: "#8f8f8f", textAlign: "center", width: "60%", margin: "0" }}>NAME</div>
                                <input
                                style={{ border: "none", margin: "auto", textAlign: "center", borderBottom: "1px solid #8f8f8f", padding: "1%", width: "75%" }}
                                type="text"
                                placeholder=""
                                value={formData.name}
                                onChange={(e) => setFormData(prevStat => {
                                    return ({
                                        ...prevStat,
                                        name: e.target.value
                                    })
                                })} />
                            </div>
                            <div style={{ margin: "3%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                                <div style={{ color: "#8f8f8f", textAlign: "center", width: "60%", margin: "0" }}>Social/Web Link</div>
                                <input
                                style={{ border: "none", margin: "auto", textAlign: "center", borderBottom: "1px solid #8f8f8f", padding: "1%", width: "75%" }}
                                type="text"
                                placeholder=""
                                value={formData.socialLink}
                                onChange={(e) => setFormData(prevStat => {
                                    return ({
                                        ...prevStat,
                                        socialLink: e.target.value
                                    })
                                })} />
                            </div>
                        </div>
                        <div className="grid-container-2 mt-2" style={{ marginLeft: "5%", marginRight: "5%" }}>
                            <div style={{ margin: "3%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                                <div style={{ color: "#8f8f8f", textAlign: "center", width: "60%", margin: "0" }}>Email</div>
                                <input
                                style={{ border: "none", margin: "auto", textAlign: "center", borderBottom: "1px solid #8f8f8f", padding: "1%", width: "75%" }}
                                type="text"
                                placeholder=""
                                value={formData.email}
                                onChange={(e) => setFormData(prevStat => {
                                    return ({
                                        ...prevStat,
                                        email: e.target.value
                                    })
                                })} />
                            </div>
                            <div style={{ margin: "3%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                                <div style={{ color: "#8f8f8f", textAlign: "center", width: "60%", margin: "0" }}>Phone</div>
                                <input
                                style={{ border: "none", margin: "auto", textAlign: "center", borderBottom: "1px solid #8f8f8f", padding: "1%", width: "75%" }}
                                type="text"
                                placeholder=""
                                value={formData.contact}
                                onChange={(e) => setFormData(prevStat => {
                                    return ({
                                        ...prevStat,
                                        contact: e.target.value
                                    })
                                })} />
                            </div>
                        </div>
                        <div style={{ margin: "3%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                            <div style={{ color: "#8f8f8f", textAlign: "center", width: "60%", margin: "0" }}>DESCRIPTION</div>
                            <textarea
                                style={{ resize: "none", border: "none", margin: "auto", textAlign: "center", borderBottom: "1px solid #8f8f8f", padding: "1%", width: "60%" }}
                                type="text"
                                placeholder="lorem ipsum....."
                                value={formData.description}
                                onChange={(e) => setFormData(prevStat => {
                                    return ({
                                        ...prevStat,
                                        description: e.target.value
                                    })
                                })} />
                        </div>
                        <div className='pb-4' style={{ marginTop: "4%", display: "flex", justifyContent: "center" }}>
                            <button className="signIn-fill"
                                style={{ width: "45%", padding: "1.5%", backgroundColor: "#235183", border: "2px solid #235183", color: "white", borderRadius: "2rem" }}>Update
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>

      </div>
    </div>
  );
};
