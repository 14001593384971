import React from "react";
import Moment from "react-moment";

export const RegisteredEventCard = ({ name, startingDate, endingDate, numberOfOrganizations, numberOfUsers }) => {
  return (
    <div
      className="row gx-0 my-2 d-flex align-items-center"
      style={{
        height: "60px",
        borderRadius: "1rem",
        backgroundColor: "#cfcfcf",
        color: "#235183",
      }}
    >
      <div className="col-2" style={{ overflow: "hidden" }}>
        <h6 style={{ padding: "10px", marginLeft: "15px" }}>{name}</h6>
      </div>
      <div className="col-2">
        <h6 style={{ padding: "10px" }}>
          <Moment date={startingDate} format="ddd DD MMM yy" />
        </h6>
      </div>
      <div className="col-2">
        <h6 style={{ padding: "10px" }}>
          <Moment date={endingDate} format="ddd DD MMM yy" />
        </h6>
      </div>
      <div className="col-2" style={{ textAlign: "center" }}>
        <h6 style={{ padding: "10px" }}>{numberOfUsers}</h6>
      </div>
      <div className="col-2" style={{ textAlign: "center" }}>
        <h6 style={{ padding: "10px" }}>{numberOfOrganizations}</h6>
      </div>
    </div>
  );
};
