import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CompanyPicture from "../../../assets/images/contact.jpg";
import profleImage from "../../../assets/images/profileDefault.png";
import { axiosAuthInstance } from "../../../axios";
import { Popup } from "../../Popup";

export const CompanyDetails = ({ inEvent }) => {
  const [pop, setPop] = useState(false);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");

  const [company, setCompany] = useState();
  const navigate = useNavigate();
  const loc = useLocation();

  const getOrgDetails = () => {
    axiosAuthInstance({
      method: "post",
      url: "api/user/getOrgDetails",
      data: { id: loc.state.orgId}
    }).then(res => {
      setCompany(res.data.message)
    })
  }

  const joinBooth = () => {
    axiosAuthInstance({
      method: "post",
      url: "api/user/requestJoinBooth",
      data: {
        organizationId: company._id,
      },
    })
      .then((res) => {
        if (res.data.statusCode == 403) {
          localStorage.clear();
          navigate("/");
        }
        if (res.data.status == "success") {
          setBody(res.data.message);
          setTitle(res.data.status);
          setPop(true);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    // setCompany(loc.state.company);
    getOrgDetails();
  }, []);

  console.log(company)

  return (
    <>
      <Popup show={pop} setShow={setPop} title={title} body={body} />
      <div className="container">
        <div className="row mt-5" style={{ backgroundColor: "#F8F9FA" }}>
          <div className="col-12 col-lg-6">
            <img
              src={company?.coverImage}
              className="img-fluid"
              style={{ width: "100vh" }}
              alt=""
            />
          </div>
          <div className="col-12 col-lg-6">
            <div className="mt-5">
              <div
                className="fw-bold my-1"
                style={{
                  fontSize: "32px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {company?.name}
              </div>
              <div className="my-1" style={{ fontWeight: "600" }}>
                Email
                <span
                  className="mx-2"
                  style={{ fontWeight: "400", color: "#235183" }}
                >
                  {company?.email}
                </span>
              </div>
              <div className="my-1" style={{ fontWeight: "600" }}>
                Phone No
                <span
                  className="mx-2"
                  style={{ fontWeight: "400", color: "#235183" }}
                >
                  {company?.contact}
                </span>
              </div>
              <div className="mt-2">
                <div style={{ fontWeight: "600" }}>Company Description</div>
                {company?.description}
              </div>
              <div className="mt-4 text-end d-flex flex-column flex-md-row">
                {inEvent ? (
                  <button
                    className="btn btn-primary mx-1 my-1"
                    style={{ backgroundColor: "#29629F" }}
                    onClick={() => {
                        if(loc?.state.joinedBooth == 0){
                            joinBooth();
                        }
                    }}
                  >
                    {loc?.state.joinedBooth == 1 ? "Requested" : loc?.state.joinedBooth == 2 ? "Joined" : "Join Booth"}
                  </button>
                ) : null}

                <button
                  className="btn btn-primary mx-1 my-1"
                  style={{ backgroundColor: "#29629F" }}
                >
                  View Company Portfolio
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
