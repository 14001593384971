import { UserCard } from "./UserCard";
import { Link, useNavigate } from "react-router-dom";
import { axiosAdminAuthInstance } from "../../../axios";
import { useEffect, useState } from "react";
import { filterBy } from "../../../functions";
import { Loader } from "../../Loader"


export const Users = ({ search }) => {
  const itemsPerPage = 10
  let navigate = useNavigate(); 

  const [isloading, setloading] = useState(true)
  let [allUsers, setAllUsers] = useState([]);
  const [userList, setUserList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);

  const getAllUsers = () => {
    axiosAdminAuthInstance({
      method: "post",
      url: "api/admin/activeUnactiveUsers",
      data: {
        status: 1,
        currentPage
      },
    })
      .then((res) => {
        if(res.data.statusCode == 403){
          localStorage.clear();
          navigate("/adminlogin")
        }
        setAllUsers(res.data.message);
        setUserList(res.data.message);
        setTotalItems(res.data.itemsCount)
        setloading(false)
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getAllUsers();
  }, [currentPage]);

  useEffect(() => {
    setUserList(filterBy(allUsers, search));
  }, [search]);

  const paginationArray = []
    let startIndex = 0
    if(Math.ceil(totalItems / itemsPerPage) == 2 || Math.ceil(totalItems / itemsPerPage) == 1){}
    else if(Math.ceil(totalItems / itemsPerPage) == 3){
        paginationArray.push(1);
    }
    else if(currentPage == Math.ceil(totalItems / itemsPerPage) - 1){
        paginationArray.push(Math.ceil(totalItems / itemsPerPage) -3);
        paginationArray.push(Math.ceil(totalItems / itemsPerPage) -2);
    }else{
        for (let i = currentPage-1; i < Math.ceil(totalItems / itemsPerPage); i++) {
        if(startIndex >= 2) break; 
        if(i < 1) continue;
        paginationArray.push(i);
        startIndex++
        }
    }

  return (
    <>
      <div style={{ height: "77vh" }}>
        <div
          className="row gx-0 d-flex justify-content-between mb-2"
          style={{
            backgroundColor: "#235183",
            borderRadius: ".5rem",
            color: "white",
          }}
        >
          <div className="col-2">
            <h3
              style={{
                padding: "8px",
                paddingBottom: 0,
                fontSize: "18px",
                marginLeft: "15px",
              }}
            >
              Name
            </h3>
          </div>
          <div className="col-2">
            <h3 style={{ padding: "8px", paddingBottom: 0, fontSize: "18px" }}>
              Email
            </h3>
          </div>
          <div className="col-2">
            <h3 style={{ padding: "8px", paddingBottom: 0, fontSize: "18px" }}>
              Phone
            </h3>
          </div>
          <div className="col-1">
            <h3
              style={{
                padding: "8px",
                paddingBottom: 0,
                fontSize: "18px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Block
            </h3>
          </div>
          <div className="col-1">
            <h3
              style={{
                padding: "8px",
                paddingBottom: 0,
                fontSize: "18px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Delete{" "}
            </h3>
          </div>
          <div className="col-1">
            <h3
              style={{
                padding: "8px",
                paddingBottom: 0,
                fontSize: "18px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              View
            </h3>
          </div>
        </div>
        <div
          className="hidescroll"
          style={{ height: "72vh", overflow: "auto" }}
        >
          {isloading ? <Loader /> : userList && typeof userList !== "string" ? (
            userList.map((element, index) => {
              return (
                <UserCard
                  key={index}
                  serial={index + 1}
                  getAllUsers={getAllUsers}
                  profileImage={element.profileImage}
                  description={element.description}
                  socialLinks={element.socialLinks}
                  isActive={element.isActive == 2 ? true : false}
                  userId={element._id}
                  name={element.name}
                  parentContainer="list"
                  email={element.email}
                  number={element.contact}
                />
              );
            })
          ) : (
            <h1>No Record Found!</h1>
          )}
        </div>
      </div>
      <div className="mt-3">
        <nav aria-label="Page navigation example">
        <ul className="pagination justify-content-end">
            <li className={currentPage < 1 ? "page-item disabled" : "page-item"}>
            <button className="page-link" to="" onClick={()=>{
                setCurrentPage(prevStat => {
                if(prevStat>=1){
                    return prevStat-1
                }
                return prevStat
                })
            }}>
                Previous
            </button>
            </li>
            <li className="page-item">
            <button className="page-link" to="" onClick={()=> setCurrentPage(0)} style={currentPage == 0 ? {background: '#235183', color:'white'} : {}}>
                1
            </button>
            </li>
            {
            Math.ceil(totalItems / itemsPerPage) > 4 && currentPage >= 3 ? <li className="page-item">
                <button className="page-link" to="" onClick={()=> setCurrentPage(prevStat => prevStat - 2)}>
                ...
                </button>
            </li>: null
            }
            {
            paginationArray.map((item, index) => {
                return <li key={index} className="page-item">
                <button className="page-link" to="" onClick={()=> setCurrentPage(item)} style={currentPage == item ? {background: '#235183', color:'white'} : {}}>
                    {item + 1}
                </button>
                </li>
            })
            }
            {
            Math.ceil(totalItems / itemsPerPage) > 4 && currentPage < Math.ceil(totalItems / itemsPerPage) - 2 ? <li className="page-item">
                <button className="page-link" to="" onClick={()=> setCurrentPage(prevStat => prevStat + 2)}>
                ...
                </button>
            </li> : null
            }
            {
            Math.ceil(totalItems / itemsPerPage) == 1 ? null : <li className="page-item">
                <button className="page-link" to="" onClick={()=> setCurrentPage(Math.ceil(totalItems / itemsPerPage) - 1)} style={currentPage == Math.ceil(totalItems / itemsPerPage) - 1 ? {background: '#235183', color:'white'} : {}}>
                {Math.ceil(totalItems / itemsPerPage)}
                </button>
            </li> 
            }
            <li className={currentPage >= Math.ceil(totalItems / itemsPerPage) - 1 ? "page-item disabled" : "page-item"}>
            <button className="page-link" to="" onClick={() => {
                setCurrentPage(prevStat => {
                    return prevStat+1
                })
            }}>
                Next
            </button>
            </li>
        </ul>
        </nav>
      </div>
    </>
  );
};
