
export const OrganizationUserCard = ({ name, email, password}) => {

    return (
        <>
            <div className="row gx-0 my-2 d-flex justify-content-between" style={{ borderRadius: "1rem", backgroundColor: "#F6F6F6", color: "#235183" }}>
                <div className="col-2" style={{ overflow: "hidden" }}>
                    <h6 className='mt-2' style={{ padding: "8px", marginLeft: "15px" }}>{name}</h6>
                </div>
                <div className="col-2">
                    <h6 className='mt-2' style={{ padding: "8px" }}>{email}</h6>
                </div>
                <div className="col-2">
                    <h6 className='mt-2' style={{ padding: "8px" }}>{password}</h6>
                </div>
            </div>
        </>
    )
}