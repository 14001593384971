import React from 'react'
import '../../assets/css/registeredModal.css'
import profile from '../../assets/images/contact.jpg'
import { ButtonMailto } from '../user/ButtonMailTo'

export const ViewPopup = ({isOpen, setIsOpen, setPopUpUser, role, eventId, name, email, number, socialLinks, profileImage, description}) => {
  return (
    <div className="stories-modal" style={{display: isOpen ? "grid" : "none"}}>
        <div className="story-card" style={{textAlign: "center",padding: "15px"}}>
            
            <img src={profileImage? profileImage : profile} style={{height: "180px", width: "180px", borderRadius: "50%"}}/>
            <h1>{name}</h1>
            <div style={{fontWeight: "600"}}>
                Email:
                <span className="mx-2" style={{fontWeight: "400", color: "#235183"}}>
                    <ButtonMailto label = {email} mailto= {email}/>
                </span>
            </div>
            <div style={{fontWeight: "600"}}>
                Phone No:
                <span className="mx-2" style={{fontWeight: "400", color:"#235183"}}>{number}</span>
            </div>
            <div >
                <div style={{fontWeight: "600"}}>
                    Description
                </div>
                <div className='' style={{height: "150px", overflow: "auto", marginRight: "10px"}}>
                    {description}
                </div>
            </div>
            <a href="#">
                <button onClick={()=> {setIsOpen(false); setPopUpUser(null)}} className='btn btn-danger mx-1'>Close</button>
            </a>
            {socialLinks?.map((element, index) => {
                return(
                    <a key={index} href={element.link}>
                        <button className='btn btn-primary mx-1'>{element.title}</button>
                    </a>
                )
            })}
        </div>
    </div>
  )
}