import { MdDelete, MdOutlineDownloadDone } from 'react-icons/md'
import { AiFillEye } from 'react-icons/ai'
import { useEffect, useState } from 'react'
import profile from '../../../assets/images/contact.jpg'
import { ButtonMailto } from '../../user/ButtonMailTo'
import { axiosAdminAuthInstance } from '../../../axios'
import { ViewPopup } from '../ViewPopup'
import { useNavigate } from 'react-router-dom'

export const UserCard = ({userId, name, email, number, isActive, parentContainer, socialLinks, description, profileImage, getAllUsers, getRequests}) => {
    const [modalOpen, setModalOpen] = useState(false)
    const [check, setCheck] = useState(isActive == true ? true : false)

    let navigate = useNavigate();

    return(
        <>
            <div className="row gx-0 my-1 d-flex justify-content-between" style={{borderRadius: "1rem",backgroundColor: "#F6F6F6", color: "#235183"}}>
                <div className="col-2" style={{overflow: "hidden"}}>
                    <h6 className='mt-2' style={{padding: "8px", marginLeft: "15px"}}>{name}</h6>
                </div>
                <div className="col-2">
                    <h6 className='mt-2' style={{padding: "8px"}}>{email}</h6>
                </div>
                <div className="col-2">
                    <h6 className='mt-2' style={{padding: "8px"}}>{number}</h6>
                </div>
                <div className="col-1">
                    <h6 style={{padding: "8px", display: "flex", justifyContent: "center", cursor: "pointer"}} onClick={() => {}}>
                        {parentContainer === "list" ? <div className="form-check form-switch mx-2">
                            <input
                                style={{height: '1.5rem', width: '3em'}} 
                                className="form-check-input" 
                                type="checkbox" 
                                role="switch" 
                                id="flexSwitchCheckDefault" 
                                checked = {check}
                                onChange = {() => {
                                    axiosAdminAuthInstance({
                                        method: "post",
                                        url: "api/admin/blockUnblockedUser",
                                        data: {
                                            userId,
                                            status: check ? 1 : 2
                                        }
                                    }).then((res)=>{
                                        if(res.data.statusCode == 403){
                                            localStorage.clear();
                                            navigate("/adminlogin")
                                        }
                                    })
                                    .catch(err => console.log(err))
                                    setCheck(prevStat => !prevStat)
                                    }
                                }
                            />
                        </div> : <MdOutlineDownloadDone 
                            size={30} 
                            color="green"
                            onClick={()=>{
                                axiosAdminAuthInstance({
                                    method: "post",
                                    url: "api/admin/userRequestAction",
                                    data: {
                                        userId,
                                        action: 1
                                    }
                                }).then(res=>{
                                    if(res.data.statusCode == 403){
                                        localStorage.clear();
                                        navigate("/adminlogin")
                                      }
                                    getRequests()
                                })
                                .catch(err=>console.log(err))
                            }}
                        />}
                    </h6>
                </div>
                <div className="col-1">
                    {parentContainer === "list" ? <h6
                        style={{padding: "8px", display: "flex", justifyContent: "center", cursor: "pointer"}} 
                        onClick={() => {
                            axiosAdminAuthInstance({
                                method: "post",
                                url: "api/admin/deleteUser",
                                data:{
                                    userId
                                }
                            }).then(res=>{
                                if(res.data.statusCode == 403){
                                    localStorage.clear();
                                    navigate("/adminlogin")
                                  }
                                getAllUsers()
                            })
                            .catch(err=>console.log(err))
                        }}>
                            <MdDelete size={30} color="red"/>
                        </h6> : <h6 
                            style={{padding: "8px", display: "flex", justifyContent: "center", cursor: "pointer"}} 
                            onClick={() => {
                                axiosAdminAuthInstance({
                                    method: "post",
                                    url: "api/admin/userRequestAction",
                                    data: {
                                        userId,
                                        action: 2
                                    }
                                }).then(res=>{
                                    if(res.data.statusCode == 403){
                                        localStorage.clear();
                                        navigate("/adminlogin")
                                      }
                                    getRequests()
                                })
                                .catch(err=>console.log(err))
                            }}>
                                <MdDelete size={30} color="red"/>
                        </h6>
                    }
                </div>
                <div className="col-1">
                    <h6 style={{padding: "8px", display: "flex", justifyContent: "center", cursor: "pointer"}} onClick={()=> setModalOpen(true)}> <AiFillEye size={30}/></h6>
                </div>
            </div>
            <ViewPopup isOpen={modalOpen} setIsOpen={setModalOpen} profileImage={profileImage} description={description} name={name} email={email} number={number} socialLinks={socialLinks}/>
        </>
    )
}