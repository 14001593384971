import React, { useState } from 'react';
import { axiosAdminAuthInstance} from '../../../../axios';
import axios from 'axios';
import { Popup } from '../../../Popup';
import DatePicker from "react-datepicker";
import coverImage1 from "../../../../assets/images/cover1.png";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from 'react-router-dom';

export const CreateEvent = () => {
    const [show, setShow] = useState(false)
    const [title, setTitle] = useState("")
    const [body, setBody] = useState("")
    const [cover, setCover] = useState("")
    const [formData, setFormData] = useState({
        name: "",
        description: "",
        eventStartDate: "",
        eventEndDate: "",
        image: ""
    })

    let navigate = useNavigate();

    const triggerUploadProfile = () => {
        let f = document.getElementById("coverImage");
        f.click();
    }

    const UploadFile = async (e) => {
        const file = e.target.files[0]

        if (file) {
            const formData = new FormData()
            // formData.append("uploadFor", 'logo')
            formData.append(
                'image',
                file,
                file.name
            )
            await axios.post('https://backend.tiptopcareerfair.com/imageUpload', formData)
                .then(res => {
                    // const result = res.data.data.successResult
                    const imageUrl = res.data.message;
                    setCover(imageUrl);
                    setFormData(prevStat => {
                        return ({
                            ...prevStat,
                            image: imageUrl
                        })
                    })
                })
                .catch(err => console.log(err))
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (formData.name === "" || formData.description === "" || formData.eventStartDate === "" || formData.eventEndDate === "" || formData.image == "") {
            // alert("Please fill up all fields")
            setTitle("Invalid Data")
            setBody("Please fill up all fields")
            setShow(true)
        } else {
            axiosAdminAuthInstance({
                method: "post",
                url: "api/admin/createEvent",
                data: {
                    ...formData,
                    isActive: 1
                },
            }).then(res => {
                if(res.data.statusCode == 403){
                    localStorage.clear();
                    navigate("/adminlogin")
                  }
                setFormData((prevStat) => {
                    return {
                        ...prevStat,
                        name: "",
                        description: "",
                        eventStartDate: "",
                        eventEndDate: "",
                    }
                })
                setTitle("Event Created")
                setBody("Event was Created")
                setShow(true)
            })
                .catch(err => console.log(err))
        }
    }
    return (
        <>
            <Popup show={show} setShow={setShow} title={title} body={body} />
            <form onSubmit={(e) => handleSubmit(e)} style={{ position: "relative" }}>
                <div>
                    {cover !== "" ?
                        <div style={{ height: "200px", backgroundImage: `url(${cover})` }}>
                            <img src={coverImage1} style={{ position: "absolute", width: "80px", right: 0, margin: "20px 20px 0px 0px", cursor:"pointer" }} onClick={() => {triggerUploadProfile()}}/>
                            <input id="coverImage" type="file" style={{ display: "none" }} onChange={(e) => {UploadFile(e)}} />

                        </div>
                        :
                        <div style={{ height: "200px", backgroundColor: "#D5E4FF", position: "relative" }}>
                            <img src={coverImage1} style={{ position: "absolute", width: "80px", right: 0, margin: "20px 20px 0px 0px", cursor:"pointer" }} onClick={() => {triggerUploadProfile()}}/>
                            <input id="coverImage" type="file" style={{ display: "none" }} onChange={(e) => {UploadFile(e)}} />
                        </div>
                    }

                    <div className='shadow' style={{ position: "absolute", width: "60%", backgroundColor: "white", top: "60%", left: "20%" }}>
                        <div style={{ margin: "5%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                            <div style={{ color: "#8f8f8f", textAlign: "center", width: "60%", margin: "0" }}>EVENT NAME</div>
                            <input
                                style={{ border: "none", margin: "auto", textAlign: "center", borderBottom: "1px solid #8f8f8f", padding: "1%", width: "60%" }}
                                type="text"
                                placeholder="Career Fair"
                                value={formData.name}
                                onChange={(e) => setFormData(prevStat => {
                                    return ({
                                        ...prevStat,
                                        name: e.target.value
                                    })
                                })} />
                        </div>
                        <div style={{ margin: "3%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                            <div style={{ color: "#8f8f8f", textAlign: "center", width: "60%", margin: "0" }}>EVENT DESCRIPTION</div>
                            <textarea
                                style={{ resize: "none", border: "none", margin: "auto", textAlign: "center", borderBottom: "1px solid #8f8f8f", padding: "1%", width: "60%" }}
                                type="text"
                                placeholder="lorem ipsum....."
                                value={formData.description}
                                onChange={(e) => setFormData(prevStat => {
                                    return ({
                                        ...prevStat,
                                        description: e.target.value
                                    })
                                })} />
                        </div>
                        <div className="grid-container-2" style={{ marginLeft: "5%", marginRight: "5%" }}>
                            <div style={{ margin: "3%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                                <div style={{ color: "#8f8f8f", textAlign: "center", width: "60%", margin: "0" }}>STARTING DATE</div>
                                <DatePicker
                                    showTimeSelect
                                    className='datePicker'
                                    placeholderText="Select Starting Date"
                                    selected={formData.eventStartDate}
                                    minDate={new Date()}
                                    onChange={(date) => setFormData(prevStat => {
                                        return ({
                                            ...prevStat,
                                            eventStartDate: date
                                        })
                                    })} />
                            </div>
                            <div style={{ margin: "3%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                                <div style={{ color: "#8f8f8f", textAlign: "center", width: "60%", margin: "0" }}>ENDING DATE</div>
                                <DatePicker
                                    showTimeSelect
                                    className='datePicker'
                                    placeholderText="Select Ending Date"
                                    selected={formData.eventEndDate}
                                    minDate={formData.eventStartDate}
                                    onChange={(date) => setFormData(prevStat => {
                                        return ({
                                            ...prevStat,
                                            eventEndDate: date
                                        })
                                    })} />
                            </div>
                        </div>

                        {/* <div className="grid-container-2" style={{marginLeft: "5%", marginRight: "5%"}}>
                    <div style={{margin: "3%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                        <div style={{color: "#8f8f8f",textAlign: "center", width: "60%", margin: "0"}}>STARTING TIME</div>
                        <input 
                            style={{border: "none", margin: "auto", textAlign: "center", borderBottom: "1px solid #8f8f8f", padding: "1%", width: "60%"}}
                            type="time"
                            placeholder="John" 
                            onChange={(e)=>setFormData(prevStat => {
                                return({
                                    ...prevStat,
                                    startingTime: e.target.value
                                })
                            })}/>
                    </div><div style={{margin: "3%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                        <div style={{color: "#8f8f8f",textAlign: "center", width: "60%", margin: "0"}}>ENDING TIME</div>
                        <input 
                            style={{border: "none", margin: "auto", textAlign: "center", borderBottom: "1px solid #8f8f8f", padding: "1%", width: "60%"}}
                            type="time"
                            placeholder="John" 
                            onChange={(e)=>setFormData(prevStat => {
                                return({
                                    ...prevStat,
                                    endingTime: e.target.value
                                })
                            })}/>
                    </div>
                </div> */}
                        <div className='pb-4' style={{ marginTop: "4%", display: "flex", justifyContent: "center" }}>
                            <button className="signIn-fill"
                                style={{ width: "45%", padding: "1.5%", backgroundColor: "#235183", border: "2px solid #235183", color: "white", borderRadius: "2rem" }}>Create
                            </button>
                        </div>
                    </div>
                </div>


            </form>
        </>
    )
}