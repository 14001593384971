import React, { useEffect, useState } from 'react'
import { Banner } from '../../components/user/ViewEvent/Banner'
import '../../assets/css/navbar.css'
import { Footer } from '../../components/user/Footer'
import { NavBar2 } from '../../components/user/NavBar2'
import { CompanyList } from '../../components/user/ViewEvent/CompanyList'
import { useLocation, useNavigate } from 'react-router-dom'
import { axiosAuthInstance } from '../../axios'

export const ViewEvent = () => {
  
  const navigate = useNavigate();
  const loc = useLocation();

  const [organizations, setOrganizations] = useState([]);



  const getEventOrganizations = () => {
    let eventId = loc.state.eventId;
    axiosAuthInstance({
        method: "post",
        url: "api/user/listEventOrganizations",
        data: {
            eventId: eventId
        }
    }).then(res => {
      console.log(res)
        if (res.data.statusCode == 403) {
          localStorage.clear();
          navigate("/");
        }
        setOrganizations(res.data.message)
    }).catch(err => console.log(err))
}

  useEffect(() => {
    getEventOrganizations();
  }, [])

  return (
    <div className='home'>
        <NavBar2/>
        <Banner/>
        <CompanyList organizations={organizations}/>
        <Footer/>
    </div>
  )
}