import React, { useState } from 'react';
import { axiosOrgAuthInstance } from '../../../axios';
import { Popup } from '../../Popup';
import { useNavigate } from 'react-router-dom';

export const CreateOrganizationUser = () => {
    const [show, setShow] = useState(false)
    const [title, setTitle] = useState("")
    const [body, setBody] = useState("")
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        password: ""
    })

    let navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault()
        if (formData.name === "" || formData.email === "" || formData.password === "") {
            setBody("Please fill up all fields")
            setShow(true)
        } else {
            axiosOrgAuthInstance({
                method: "post",
                url: "api/organization-user",
                data: {
                    ...formData,
                    isActive: 1,
                    isDeleted: 0
                },
            }).then(res => {
                if (res.data.statusCode == 403) {
                    localStorage.clear();
                    navigate("/organizationLogin")
                }
                setFormData((prevStat) => {
                    return {
                        ...prevStat,
                        name: "",
                        email: "",
                        password: "",
                    }
                })
                setTitle("User Created")
                setBody("User was Created")
                setShow(true)
            })
                .catch(err => console.log(err))
        }
    }
    return (
        <>
            <Popup show={show} setShow={setShow} title={title} body={body} />
            <form onSubmit={(e) => handleSubmit(e)} style={{ position: "relative", margin: '7% 0 0' }}>
                <div>

                    <div className='shadow' style={{ position: "absolute", width: "60%", backgroundColor: "white", top: "70%", left: "20%" }}>
                        <div style={{ margin: "5%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                            <div style={{ color: "#8f8f8f", textAlign: "center", width: "60%", margin: "0" }}>USER NAME</div>
                            <input
                                style={{ border: "none", margin: "auto", textAlign: "center", borderBottom: "1px solid #8f8f8f", padding: "1%", width: "60%" }}
                                type="text"
                                placeholder="Enter Name Here"
                                value={formData.name}
                                onChange={(e) => setFormData(prevStat => {
                                    return ({
                                        ...prevStat,
                                        name: e.target.value
                                    })
                                })} />
                        </div>
                        <div style={{ margin: "5%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                            <div style={{ color: "#8f8f8f", textAlign: "center", width: "60%", margin: "0" }}>USER EMAIL</div>
                            <input
                                style={{ border: "none", margin: "auto", textAlign: "center", borderBottom: "1px solid #8f8f8f", padding: "1%", width: "60%" }}
                                type="text"
                                placeholder="Enter Email Here"
                                value={formData.email}
                                onChange={(e) => setFormData(prevStat => {
                                    return ({
                                        ...prevStat,
                                        email: e.target.value
                                    })
                                })} />
                        </div>
                        <div style={{ margin: "5%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                            <div style={{ color: "#8f8f8f", textAlign: "center", width: "60%", margin: "0" }}>USER PASSWORD</div>
                            <input
                                style={{ border: "none", margin: "auto", textAlign: "center", borderBottom: "1px solid #8f8f8f", padding: "1%", width: "60%" }}
                                type="text"
                                placeholder="Enter Password Here"
                                value={formData.password}
                                onChange={(e) => setFormData(prevStat => {
                                    return ({
                                        ...prevStat,
                                        password: e.target.value
                                    })
                                })} />
                        </div>
                                
                        <div className='pb-4' style={{ marginTop: "4%", display: "flex", justifyContent: "center" }}>
                            <button className="signIn-fill"
                                style={{ width: "45%", padding: "1.5%", backgroundColor: "#235183", border: "2px solid #235183", color: "white", borderRadius: "2rem" }}>Create
                            </button>
                        </div>
                    </div>
                </div>


            </form>
        </>
    )
}