import { Footer } from "../../components/user/Footer"
import { NavBar } from "../../components/user/NavBar"
import { ButtonMailto } from "../../components/user/ButtonMailTo"
import { useEffect, useState } from 'react'
import { BiLink } from 'react-icons/bi'
import { RiFileDownloadLine } from 'react-icons/ri'
import { axiosAuthInstance } from "../../axios"
import ProfileModal from "../../components/user/userProfile/ProfileModal"
import { useNavigate } from "react-router-dom"

export const UserProfile = () => {

    let navigate = useNavigate();

    const downloadCV = (e) => {
        e.preventDefault();
       
       
        // window.location.assign(userData?.CV)
        window.open(userData?.CV, '_blank').focus();
    }

    const [userData, setUserData] = useState({
        name: "",
        email: "",
        contact: "",
        description: "",
        profileImage: "",
        socialLinks: [
            {
                title: "",
                link: ""
            },
            {
                title: "",
                link: ""
            },
            {
                title: "",
                link: ""
            }
        ],
        CV: ""
    })

    const getUserProfile = () => {
        axiosAuthInstance({
            method: "post",
            url: "api/user/userDetails",
        }).then(res => {
            if (res.data.statusCode == 403) {
                localStorage.clear();
                navigate("/");
            }
            if (res.data.status === "success") {
                setUserData(prevStat => {
                    return ({
                        ...prevStat,
                        name: res.data.message.name,
                        email: res.data.message.email,
                        contact: res.data.message.contact,
                        profileImage: res.data.message.profileImage,
                        description: res.data.message.description,
                        socialLinks: [...res.data.message.socialLinks],
                        CV: res.data.message.CV
                    })
                })
            }
        }).catch(err => console.log(err))
    }

    useEffect(() => {
        getUserProfile()
    }, [])

    const [modalOpen, setModalOpen] = useState(false)
    return (
        <>
            <NavBar />
            <div className="container" style={{ minHeight: "569px" }}>
                <div className="row mt-5">
                    <div className="col-12 col-lg-3 text-center text-lg-start">
                        <div>
                            <div>
                                <img src={userData.profileImage} className="img-fluid" style={{ width: "260px", height: "250px" }} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-9 text-center text-lg-start">
                        <div className="">
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <div className="my-1" style={{ display: "inline", fontSize: "32px", color: "#7a8893", fontWeight: "500" }}>
                                    {userData.name}
                                </div>
                                <button className="btn btn-primary" onClick={() => setModalOpen(!modalOpen)}>Edit</button>
                            </div>
                            <div className="my-1 mx-1" style={{ fontWeight: "600", color: "#235183" }}>
                                Web Developer
                            </div>
                            <hr className="w-50 m-auto m-lg-0" />
                            <div className="my-1" style={{ fontWeight: "600" }}>
                                Email
                                <span className="mx-2" style={{ fontWeight: "400", color: "#235183" }}>
                                    <ButtonMailto label={userData.email} mailto={userData.email} />
                                </span>
                            </div>
                            <div className="my-1" style={{ fontWeight: "600" }}>
                                Phone No
                                <span className="mx-2" style={{ fontWeight: "400", color: "#235183" }}>+{userData.contact}</span>
                            </div>
                            <div className="mt-2">
                                <div style={{ fontWeight: "600" }}>
                                    Description
                                </div>
                                {userData.description}
                            </div>
                            <hr className="w-50 m-auto m-lg-0" />
                            <div className="mt-2 mb-5">
                                <div style={{ fontWeight: "600" }}>
                                    Social Links
                                </div>
                                <div>
                                    {userData.socialLinks.map((item, index) => {
                                        return (
                                            <div className="my-1" key={index}>
                                                <a
                                                    target="_blank"
                                                    href={item.link}
                                                    style={{ textDecoration: "none", color: "black", fontWeight: "600" }}
                                                >
                                                    <BiLink />{item.title}</a>
                                            </div>
                                        )
                                    })}
                                    <div className="my-3">
                                        <button className="btn btn-primary" style={{ backgroundColor: "#235183", border: "none", display: "flex", alignItems: "center" }} onClick={(e) => downloadCV(e)}>
                                            <RiFileDownloadLine size={25} />
                                            <span style={{ paddingLeft: "8px" }}>Download CV</span>
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ProfileModal userData={userData} modalOpen={modalOpen} setModalOpen={setModalOpen} getUserProfile={getUserProfile}/>

            <Footer />
        </>
    )
}