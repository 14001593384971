import { Banner } from "../../components/user/candidateHome/Banner"
import { NavBar2 } from "../../components/user/NavBar2"
import '../../assets/css/navbar.css'
import { CompanyList } from "../../components/user/candidateHome/CompanyList"
import { Footer } from "../../components/user/Footer"
import { OnGoingEventList } from "../../components/user/candidateHome/OnGoingEventList"
import { UpComingEventList } from "../../components/user/candidateHome/UpComingEventList"
import { useDebugValue, useEffect, useState } from "react"
import BorderExample from "../../components/Bootstap/Spinner"

export const CandidadteHome = () => {
    const [search, setSearch] = useState("");
    const [companyList,setCompanyList] = useState([])
    useEffect(()=>{},[companyList])

    return (
        <>
            <section className="home">
                <NavBar2 />
                <Banner companyList={companyList} search={search} setSearch={setSearch}/>
                <OnGoingEventList limit={4}/>
                <UpComingEventList limit={4}/>
                <CompanyList limit={4} companyList={companyList} setCompanyList={setCompanyList} search={search.toLocaleLowerCase()}/>
                <Footer />
            </section>
        </>
    )
}