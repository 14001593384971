import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"
import { axiosOrgAuthInstance } from "../../../axios";
import { Popup } from "../../Popup";
import { ButtonMailto } from "../../user/ButtonMailTo";

export const UserDetails = () => {
    
    const [pop, setPop] = useState(false);
    const [title, setTitle] = useState("");
    const [body, setBody] = useState("");

    const [user, setUser] = useState();
    const navigate = useNavigate();
    const loc = useLocation();

    const getUserDetails = () => {
        axiosOrgAuthInstance({
            method: "post",
            url: "api/organization/getUserDetails",
            data: {
                id: loc.state.user
            }
        }).then(res => {
            console.log(res)
            setUser(res.data.message)
        }).catch(err => console.log(err.message))
    }

    useEffect(() => {
      getUserDetails()
    }, [])
    console.log(user)
    return (
        <>
            <Popup show={pop} setShow={setPop} title={title} body={body} />
            <div className="container" style={{minHeight: "75vh"}}>
                <div className="row mt-5" style={{ backgroundColor: "#F8F9FA" }}>
                    <div className="col-12 col-lg-6">
                        <img src={user?.profileImage} className="img-fluid" style={{ width: "100vh" }} alt="" />
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="mt-5">
                            <div className="fw-bold my-1" style={{ fontSize: "32px", display: "flex", justifyContent: "space-between" }}>
                                {user?.name}
                            </div>
                            <div className="my-1" style={{ fontWeight: "600" }}>
                                Email
                                <span onClick={() => window.open(`mailto:${user?.email}`)} className="mx-2" style={{ fontWeight: "400", color: "#235183", cursor: "pointer" }}>
                                  {user?.email}
                                </span>
                            </div>
                            <div className="my-1" style={{ fontWeight: "600" }}>
                                Phone No
                                <span className="mx-2" style={{ fontWeight: "400", color: "#235183" }}>{user?.contact}</span>
                            </div>
                            <div className="mt-2">
                                <div style={{ fontWeight: "600" }}>
                                    User Description
                                </div>
                                {user && user.description ? user.description : "no description provided by user"}
                            </div>
                            <div className="mt-4 text-end d-flex flex-column flex-md-row">
                                {/* {inEvent ? <button className="btn btn-primary mx-1 my-1" style={{ backgroundColor: "#29629F" }} onClick={() => { joinBooth() }}>Join Booth</button> : null} */}

                                <a href={user?.CV} target="_blank" className="btn btn-primary mx-1 my-1" style={{ backgroundColor: "#29629F" }}>View User Resume</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}