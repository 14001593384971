import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { CompanyDetails } from '../companyProfile/CompanyDetails'
import { Footer } from '../Footer'
import { NavBar } from '../NavBar'

export const CompanyProfile2 = () => {
  
  const navigate = useNavigate();
  const loc = useLocation();



  return (
    <>
        <NavBar />
        <CompanyDetails inEvent={true}/>
        <div style={{height: "14vh"}}>

        </div>
        <Footer />
    </>
  )
}
