import React from 'react'
import { OrgNavbar2 } from '../../components/organization/OrgNavbar2'
import { UserDetails } from '../../components/organization/ViewEvent/UserDetails'
import { Footer } from '../../components/user/Footer'

export const UserEventProfile = () => {
  return (
    <>
      <OrgNavbar2/>
      <UserDetails/>
      <Footer/>
    </>
  )
}
