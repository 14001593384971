import { MdDelete, MdOutlineDownloadDone } from "react-icons/md";
import { AiFillEye } from "react-icons/ai";
import { axiosAdminAuthInstance } from "../../../../axios";
import { useNavigate } from "react-router-dom";

export const UserReqCard = ({ getRequests, user, setPopUpUser, setIsOpen }) => {
  let navigate = useNavigate();

  const acceptRequest = () => {
    axiosAdminAuthInstance({
      method: "post",
      url: "api/admin/acceptUserEventRequest",
      data: {
        userId: user.userId,
        eventId: user._id,
      },
    })
      .then((res) => {
        if(res.data.statusCode == 403){
          localStorage.clear();
          navigate("/adminlogin")
        }
        getRequests();
      })
      .catch((err) => console.log(err));
  };

  const rejectRequest = () => {
    axiosAdminAuthInstance({
      method: "post",
      url: "api/admin/rejectUserEventRequest",
      data: {
        userId: user.userId,
        eventId: user._id,
      },
    })
      .then((res) => {
        if(res.data.statusCode == 403){
          localStorage.clear();
          navigate("/adminlogin")
        }
        getRequests();
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div
        className="row gx-0 my-2 d-flex align-items-center"
        style={{
          height: "60px",
          borderRadius: "1rem",
          backgroundColor: "#cfcfcf",
          color: "#235183",
        }}
      >
        <div className="col-3" style={{ overflow: "hidden" }}>
          <h6 style={{ padding: "10px", marginLeft: "15px" }}>
            {user.userName}
          </h6>
        </div>
        <div className="col-3">
          <h6 style={{ padding: "10px" }}>{user.email}</h6>
        </div>
        <div className="col-3">
          <h6 style={{ padding: "10px" }}>{user.name}</h6>
        </div>

        <div className="col-1">
          <h6
            style={{
              padding: "10px",
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={() => acceptRequest()}
          >
            <MdOutlineDownloadDone size={30} color="green" />
          </h6>
        </div>
        <div className="col-1">
          <h6
            style={{
              padding: "10px",
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={() => rejectRequest()}
          >
            <MdDelete size={30} color="red" />
          </h6>
        </div>
        <div className="col-1">
          <h6
            style={{
              padding: "10px",
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              setPopUpUser(user);
              setIsOpen(true);
            }}
          >
            {" "}
            <AiFillEye size={30} />
          </h6>
        </div>
      </div>
    </>
  );
};
