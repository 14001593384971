import React, { useEffect, useState } from "react";
import "../../../../assets/css/registeredModal.css";
import { axiosAdminAuthInstance } from "../../../../axios";
import { AiFillCloseCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

export const Registered = ({ isOpen, setIsOpen, role, eventId }) => {
  const [list, setList] = useState([]);

  let navigate = useNavigate();

  useEffect(() => {
    if (role === "Users") {
      axiosAdminAuthInstance({
        method: "post",
        url: "api/admin/listEventUser",
        data: {
          eventId,
        },
      })
        .then((res) => {
          if(res.data.statusCode == 403){
            localStorage.clear();
            navigate("/adminlogin")
          }
          if (res.data.status === "success") {
            setList(res.data.message.registeredUser);
          }
        })
        .catch((err) => console.log(err));
    } else if (role === "Organizations") {
      axiosAdminAuthInstance({
        method: "post",
        url: "api/admin/listEventOrganizations",
        data: {
          eventId,
        },
      })
        .then((res) => {
          if(res.data.statusCode == 403){
            localStorage.clear();
            navigate("/adminlogin")
          }
          if (res.data.status === "success") {
            setList(() => res.data.message.registeredOrganization);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [isOpen]);



  return (
    <div
      className="stories-modal"
      style={{ display: isOpen ? "grid" : "none" }}
    >
      <div className="story-card">
        <div className="story-head">
          <span style={{ fontSize: "28px" }}>
            <strong className="first">Registered </strong>
            {role}
          </span>
          <span
            className="action-story"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <AiFillCloseCircle size={30} />
          </span>
        </div>
        <div
          className="hidescroll"
          style={{ overflow: "auto", height: "480px" }}
        >
          {list.map((element, index) => {
            return (
              <div key={index} className="story-view">
                <img
                  src={
                    role === "Users"
                      ? element.userId.profileImage
                      : role === "Organizations"
                      ? element.organizationId.coverImage
                      : null
                  }
                  style={{ height: "40px", width: "40px" }}
                />
                <h4 style={{ paddingLeft: "10px" }}>
                  {role === "Users"
                    ? element.userId.name
                    : role === "Organizations"
                    ? element.organizationId.name
                    : null}
                </h4>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
