import React, { useEffect, useState } from "react";
import { RiCloseCircleFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { axiosAuthInstance, axiosInstance } from "../../../axios";
import { linkValidation } from "../../../functions";

const ProfileModal = ({modalOpen, setModalOpen, userData, getUserProfile}) => {
  let navigate = useNavigate();
  
  const [updateData, setUpdateData] = useState({
    name: "",
    email: "",
    contact: "",
    description: "",
    profileImage: "",
    socials: {
      social1: {
        title: "",
        link: "",
      },
      social2: {
        title: "",
        link: "",
      },
      social3: {
        title: "",
        link: "",
      },
    },
  });
  useEffect(() => {
    setUpdateData((prevStat) => {
      return {
        ...prevStat,
        name: userData.name,
        email: userData.email,
        contact: userData.contact,
        description: userData.description,
        profileImage: userData.profileImage,
        socials: {
          ...prevStat.socials,
          social1: {
            ...prevStat.socials.social1,
            link: userData.socialLinks[0].link,
            title: userData.socialLinks[0].title,
          },
          social2: {
            ...prevStat.socials.social2,
            link: userData.socialLinks[1].link,
            title: userData.socialLinks[1].title,
          },
          social3: {
            ...prevStat.socials.social3,
            link: userData.socialLinks[2].link,
            title: userData.socialLinks[2].title,
          },
        },
      };
    });
  }, [userData]);


  const updateSubmit = (e) => {
    e.preventDefault();

    const social = [];
    Object.keys(updateData.socials).forEach((key, index) => {
      const socialObject = updateData.socials[key];
      if (!linkValidation(socialObject.link) && socialObject.link != "") {
        socialObject.link = `https://${socialObject.link}`;
      }
      social.push(socialObject);
    });
   
    axiosAuthInstance({
      method: "post",
      url: "api/user/update",
      data: {
        ...updateData,
        socialLinks: [...social],
      },
    })
      .then((res) => {
        if (res.data.statusCode == 403) {
          localStorage.clear();
          navigate("/");
        }
        getUserProfile()
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <div
      className={modalOpen ? "activemodal shadow" : "modal"}
      style={{ background: "rgba(0,0,0,0.8)" }}
    >
      <div
        className="item"
        style={{
          marginTop: "2%",
          position: "relative",
          top: "20%",
          borderRadius: "1rem",
          left: "31%",
          textAlign: "center",
          backgroundColor: "white",
          width: "40%",
        }}
      >
        <div style={{ textAlign: "right" }}>
          <RiCloseCircleFill
            onClick={() => setModalOpen(false)}
            size={40}
            style={{
              marginRight: "10px",
              marginTop: "10px",
              cursor: "pointer",
              color: "#235183",
            }}
          />
        </div>
        <form onSubmit={(e) => updateSubmit(e)}>
          <div style={{ position: "relative" }}>
            <div
              style={{ position: "absolute", left: "54%", top: "70%" }}
            ></div>
            <img
              src={userData.profileImage}
              style={{ width: "140px", height: "120px", borderRadius: "50%" }}
              alt=""
            />
          </div>
          <div
            className="grid-container-2"
            style={{ marginLeft: "5%", marginRight: "5%" }}
          >
            <div style={{ margin: "1%" }}>
              <label style={{ color: "#8f8f8f" }}>Name</label>
              <br />
              <input
                value={updateData.name}
                onChange={(e) =>
                  setUpdateData((prevState) => {
                    return {
                      ...prevState,
                      name: e.target.value,
                    };
                  })
                }
                style={{
                  border: "none",
                  borderBottom: "1px solid #8f8f8f",
                  padding: "1%",
                  width: "70%",
                }}
                type="text"
              />
            </div>
            <div style={{ margin: "1%" }}>
              <label style={{ color: "#8f8f8f" }}>Email</label>
              <br />
              <input
                value={updateData.email}
                onChange={(e) =>
                  setUpdateData((prevState) => {
                    return {
                      ...prevState,
                      email: e.target.value,
                    };
                  })
                }
                style={{
                  border: "none",
                  borderBottom: "1px solid #8f8f8f",
                  padding: "1%",
                  width: "70%",
                }}
                type="text"
              />
            </div>
            <div style={{ margin: "1%" }}>
              <label style={{ color: "#8f8f8f" }}>Phone Number</label>
              <br />
              <input
                value={updateData.contact}
                onChange={(e) =>
                  setUpdateData((prevState) => {
                    return {
                      ...prevState,
                      contact: e.target.value,
                    };
                  })
                }
                style={{
                  border: "none",
                  borderBottom: "1px solid #8f8f8f",
                  padding: "1%",
                  width: "70%",
                }}
                type="text"
              />
            </div>
            <div style={{ margin: "1%" }}>
              <label style={{ color: "#8f8f8f" }}>Description</label>
              <br />
              <input
                value={updateData.description}
                onChange={(e) =>
                  setUpdateData((prevState) => {
                    return {
                      ...prevState,
                      description: e.target.value,
                    };
                  })
                }
                style={{
                  border: "none",
                  borderBottom: "1px solid #8f8f8f",
                  padding: "1%",
                  width: "70%",
                }}
                type="text"
              />
            </div>
          </div>

          <div style={{ margin: "1%" }}>
            <label style={{ color: "#8f8f8f" }}>Link Titles</label>
            <br />
          </div>

          <div
            className="grid-container-3"
            style={{ marginLeft: "8%", marginRight: "8%" }}
          >
            <div style={{ margin: "1%" }}>
              <input
                value={updateData.socials.social1.title}
                onChange={(e) =>
                  setUpdateData((prevStat) => {
                    return {
                      ...prevStat,
                      socials: {
                        ...prevStat.socials,
                        social1: {
                          ...prevStat.socials.social1,
                          title: e.target.value,
                        },
                      },
                    };
                  })
                }
                style={{
                  border: "none",
                  borderBottom: "1px solid #8f8f8f",
                  padding: "1%",
                  width: "75%",
                }}
                type="text"
              />
            </div>
            <div style={{ margin: "1%" }}>
              <input
                value={updateData.socials.social2.title}
                onChange={(e) =>
                  setUpdateData((prevStat) => {
                    return {
                      ...prevStat,
                      socials: {
                        ...prevStat.socials,
                        social2: {
                          ...prevStat.socials.social2,
                          title: e.target.value,
                        },
                      },
                    };
                  })
                }
                style={{
                  border: "none",
                  borderBottom: "1px solid #8f8f8f",
                  padding: "1%",
                  width: "70%",
                }}
                type="text"
              />
            </div>
            <div style={{ margin: "1%" }}>
              <input
                value={updateData.socials.social3.title}
                onChange={(e) =>
                  setUpdateData((prevStat) => {
                    return {
                      ...prevStat,
                      socials: {
                        ...prevStat.socials,
                        social3: {
                          ...prevStat.socials.social3,
                          title: e.target.value,
                        },
                      },
                    };
                  })
                }
                style={{
                  border: "none",
                  borderBottom: "1px solid #8f8f8f",
                  padding: "1%",
                  width: "70%",
                }}
                type="text"
              />
            </div>
          </div>
          <div style={{ margin: "1%" }}>
            <label style={{ color: "#8f8f8f" }}>SOCIAL MEDIA LINKS</label>
            <br />
          </div>

          <div
            className="grid-container-3"
            style={{ marginLeft: "8%", marginRight: "8%" }}
          >
            <div style={{ margin: "1%" }}>
              <input
                value={updateData.socials.social1.link}
                onChange={(e) =>
                  setUpdateData((prevStat) => {
                    return {
                      ...prevStat,
                      socials: {
                        ...prevStat.socials,
                        social1: {
                          ...prevStat.socials.social1,
                          link: e.target.value,
                        },
                      },
                    };
                  })
                }
                style={{
                  border: "none",
                  borderBottom: "1px solid #8f8f8f",
                  padding: "1%",
                  width: "75%",
                }}
                type="text"
              />
            </div>
            <div style={{ margin: "1%" }}>
              <input
                value={updateData.socials.social2.link}
                onChange={(e) =>
                  setUpdateData((prevStat) => {
                    return {
                      ...prevStat,
                      socials: {
                        ...prevStat.socials,
                        social2: {
                          ...prevStat.socials.social2,
                          link: e.target.value,
                        },
                      },
                    };
                  })
                }
                style={{
                  border: "none",
                  borderBottom: "1px solid #8f8f8f",
                  padding: "1%",
                  width: "70%",
                }}
                type="text"
              />
            </div>
            <div style={{ margin: "1%" }}>
              <input
                value={updateData.socials.social3.link}
                onChange={(e) =>
                  setUpdateData((prevStat) => {
                    return {
                      ...prevStat,
                      socials: {
                        ...prevStat.socials,
                        social3: {
                          ...prevStat.socials.social3,
                          link: e.target.value,
                        },
                      },
                    };
                  })
                }
                style={{
                  border: "none",
                  borderBottom: "1px solid #8f8f8f",
                  padding: "1%",
                  width: "70%",
                }}
                type="text"
              />
            </div>
          </div>
          <div style={{ marginTop: "1%" }}>
            <button
              onClick={() => setModalOpen(false)}
              className=""
              style={{
                margin: "3%",
                width: "10%",
                padding: "0.7%",
                backgroundColor: "#235183",
                border: "2px solid #235183",
                color: "white",
                borderRadius: "2rem",
              }}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileModal;
