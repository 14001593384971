export const validateEmail = (email) => {
    return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
};

//Phone Number Validaton
export const phoneValidation=(num)=>{
    return num.match(
        /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/
    )
  }
//Phone Number Validaton
//Name Validation
export const nameValidation = (user)=>{

return user.match(
    /^[a-zA-Z ]{2,50}.$/
    // /^[A-Za-z]+$/
)
}
//Name Validation
//URL Validation
export const linkValidation = (link)=>{
  
    return link.indexOf("https://") == 0 || link.indexOf("http://") == 0
}
// export const linkValidation = (link)=>{
//     return link.match(
//         /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/
//     )
// }
//URL Validation
export const filterBy = (arr, search) => {
    return (arr?.filter(word => (word.name.toLowerCase().indexOf(search) > -1 || word.email.toLowerCase().indexOf(search) > -1 || word.contact?.toLowerCase().indexOf(search) > -1)));
}

export const filterEvents = (arr, search) => {
    return (arr.filter(word => (word.name.toLowerCase().indexOf(search) > -1 )));
}

export const endSession = () => {
    localStorage.clear()
    
}