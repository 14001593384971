import { faScaleBalanced } from '@fortawesome/free-solid-svg-icons'
import {useState} from 'react'
import { ViewPopup } from '../../ViewPopup'
import { OrgRequests } from './OrgRequests'
import { UserRequests } from './UserRequests'

export const Requests = ({search}) => {
    const [position, setPosition] = useState(0)
    const [popUpUser, setPopUpUser] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenOrg, setIsOpenOrg] = useState(false);
  return (
    <>
        {isOpen && popUpUser !== null ? <ViewPopup isOpen={isOpen} setIsOpen={setIsOpen} setPopUpUser={setPopUpUser} name={popUpUser.userName} email={popUpUser.email} number={popUpUser.contact} description={popUpUser.description}/> : null}
        {isOpenOrg && popUpUser !== null ? <ViewPopup isOpen={isOpenOrg} setIsOpen={setIsOpenOrg} setPopUpUser={setPopUpUser} name={popUpUser.organizationName} email={popUpUser.email} number={popUpUser.contact} description={popUpUser.description}/> : null}
        <div style={{display: "flex", border: "3px solid #235183", borderRadius: '2rem'}}>
            <button className='m-1' onClick={() => setPosition(0)} style={{border: `${position == 0 ? 'none' : "2px solid #235183"}`, width: "100%",height: "50px", backgroundColor: `${position == 0 ? '#235183' : "#ffffff"}`, borderRadius: "2rem",color: `${position == 0 ? '#ffffff' : "#235183"}`}}>
                <span style={{fontSize: `${position == 0 ? '26px' : "15px"}`}}>
                    USERS
                </span>
            </button>
            <button className="m-1" onClick={() => setPosition(1)} style={{border: `${position == 1 ? 'none' : "2px solid #235183"}`, width: "100%",height: "50px", backgroundColor: `${position == 0 ? '#ffffff' : "#235183"}`, borderRadius: "2rem",color: `${position == 1 ? '#ffffff' : "#235183"}`}}>
                <span style={{fontSize: `${position == 1 ? '26px' : "15px"}`}}>
                    ORGANIZATIONS
                </span>
            </button>
        </div>
        <div className='mt-2' style={{display: "flex", overflow: "hidden"}}>
            <div className="transitionAnimation" style={{minWidth: "100%", transform: `translateX(-${position}00%)`, height: "78vh"}}>
                <UserRequests search={search} setPopUpUser={setPopUpUser} setIsOpen={setIsOpen}/>
            </div>
            <div className="transitionAnimation" style={{minWidth: "100%", transform: `translateX(-${position}00%)`, height: "78vh"}}>
                <OrgRequests search={search} setPopUpUser={setPopUpUser} setIsOpen={setIsOpenOrg}/>
            </div>
        </div>
    </>
  )
}
