export const Popup = ({ show, setShow, title, body }) => {
  return (
    <div className="stories-modal" style={{display: show ? "block" : "none"}}>
                <div className="container border" style={{
                    backgroundColor: "white",
                    position: "relative", 
                    padding: "30px 20px 30px 20px",
                    width: "35%",
                    top: "26%",
                }}>
                    <div style={{textAlign:"center"}}>
                        <h1 style={{borderBottom: "1px solid black"}}>{title}</h1>
                        <h6 style={{marginTop: "15px"}}>{body}</h6>
                        <button className="btn" style={{backgroundColor: "#235183", color: "white",padding:"11px 59px"}} onClick={()=>setShow(false)}>Close</button>
                    </div>
                </div>
    </div>
  )
}
