import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosAuthInstance } from "../../../axios";
import { Loader } from "../../Loader";
import { OnGoingEventCard } from "./OnGoingEventCard";

export const UpComingEventList = ({limit}) => {
  const eventsPerPage = 8

  let navigate = useNavigate();
  const [isloading, setloading] = useState(true);
  const [events, setEvents] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalEvents, setTotalEvents] = useState(0);

  const getEvents = () => {
    axiosAuthInstance({
      method: "post",
      url: "api/user/listUpcommingEvents",
      data: {
        limit: limit,
        currentPage,
      },
    })
      .then((res) => {
        if (res.data.statusCode == 403) {
          localStorage.clear();
          navigate("/");
        }
        if(res.data.status === "success"){
          setEvents(res.data.message);
          setTotalEvents(res.data.eventsCount);
        }
        setloading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getEvents();
  }, [currentPage]);

  
  const paginationArray = []
  let startIndex = 0
  if(Math.ceil(totalEvents / eventsPerPage) == 2 || Math.ceil(totalEvents / eventsPerPage) == 1){}
  else if(Math.ceil(totalEvents / eventsPerPage) == 3){
    paginationArray.push(1);
  }
  else if(currentPage == Math.ceil(totalEvents / eventsPerPage) - 1){
    paginationArray.push(Math.ceil(totalEvents / eventsPerPage) -3);
    paginationArray.push(Math.ceil(totalEvents / eventsPerPage) -2);
  }else{
    for (let i = currentPage-1; i < Math.ceil(totalEvents / eventsPerPage); i++) {
      if(startIndex >= 2) break; 
      if(i < 1) continue;
      paginationArray.push(i);
      startIndex++
    }
  }

  return (
    <div className="container my-4">
      <div>
        <h1 className="text-center" style={{ color: "#235183" }}>
          Up Coming Events
        </h1>
      </div>
      <div className="mt-2">
        <div className="row">
          {isloading ? (
            <Loader />
          ) : (
            events.length == 0 ? <h5 style={{textAlign: 'center'}}>no upcoming events</h5> :
            events?.map((item, index) => {
              return (
                <OnGoingEventCard key={index}
                  getEvents={getEvents}
                  buttonText={
                    item.Joined == 0
                      ? "Register"
                      : item.Joined == 2
                      ? "Approval Pending"
                      : item.Joined == 1
                      ? "Approved"
                      : null
                  }
                  colour={
                    item.Joined == 0
                      ? "#235183"
                      : item.Joined == 2
                      ? "grey"
                      : item.Joined == 1
                      ? "green"
                      : null
                  }
                  event={item}
                />
              );
            })
          )}
        </div>
      </div>
      {limit ?<div
        className="mt-4"
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <button
          className="btn"
          style={{ backgroundColor: "#235183", color: "white" }}
          onClick={() => navigate("/allUpcomingEvents")}
        >
          See All
        </button>
      </div> : null}

      {limit ? null : <div className="mt-3">
        <nav aria-label="Page navigation example">
          <ul className="pagination justify-content-end">
            <li className={currentPage < 1 ? "page-item disabled" : "page-item"}>
              <button className="page-link" to="" onClick={()=>{
                setCurrentPage(prevStat => {
                  if(prevStat>=1){
                    return prevStat-1
                  }
                  return prevStat
                })
              }}>
                Previous
              </button>
            </li>
            <li className="page-item">
              <button className="page-link" to="" onClick={()=> setCurrentPage(0)} style={currentPage == 0 ? {background: '#235183', color:'white'} : {}}>
                1
              </button>
            </li>
            {
              Math.ceil(totalEvents / eventsPerPage) > 4 && currentPage >= 3 ? <li className="page-item">
                <button className="page-link" to="" onClick={()=> setCurrentPage(prevStat => prevStat - 2)}>
                  ...
                </button>
              </li>: null
            }
            {
              paginationArray.map((item, index) => {
                return <li key={index} className="page-item">
                  <button className="page-link" to="" onClick={()=> setCurrentPage(item)} style={currentPage == item ? {background: '#235183', color:'white'} : {}}>
                    {item + 1}
                  </button>
                </li>
              })
            }
            {
              Math.ceil(totalEvents / eventsPerPage) > 4 && currentPage < Math.ceil(totalEvents / eventsPerPage) - 2 ? <li className="page-item">
                <button className="page-link" to="" onClick={()=> setCurrentPage(prevStat => prevStat + 2)}>
                  ...
                </button>
              </li> : null
            }
            {
              Math.ceil(totalEvents / eventsPerPage) == 1 ? null : <li className="page-item">
                <button className="page-link" to="" onClick={()=> setCurrentPage(Math.ceil(totalEvents / eventsPerPage) - 1)} style={currentPage == Math.ceil(totalEvents / eventsPerPage) - 1 ? {background: '#235183', color:'white'} : {}}>
                  {Math.ceil(totalEvents / eventsPerPage)}
                </button>
              </li> 
            }
            <li className={currentPage >= Math.ceil(totalEvents / eventsPerPage) - 1 ? "page-item disabled" : "page-item"}>
              <button className="page-link" to="" onClick={() => {
                  setCurrentPage(prevStat => {
                    return prevStat+1
                  })
              }}>
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>}

    </div>
  );
};
